import * as React from 'react';
import Container from '@mui/material/Container';
import { Typography, IconButton } from '@mui/material';
import { useNavigate } from "react-router-dom";

import LoginIcon from '@mui/icons-material/Login';

function HomePage() {
    let navigate = useNavigate();

    return (
        <Container maxWidth="xl" align="center" >
            <h1>Zubná ambulancia</h1>
            <Typography>Táto webová aplikácia slúži pre potreby Zubnej ambulancie </Typography>
            <Typography>Pre prístup k aplikácii sa najprv prihláste </Typography>
            <IconButton aria-label="back" onClick={() => navigate("/prihlasenie")} >
                <LoginIcon fontSize="large" />
            </IconButton>
        </Container>
    );
};

export default HomePage;