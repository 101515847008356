import "./App.css";

import * as React from "react";
import { useContext } from "react";
import { UserContext } from './middleware/userContext';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import { SnackbarProvider } from 'notistack';

import NavbarAuthorized from './navbar/navbarAuthorized'; //Veľké písmeno na začiatku, inak error
import NavbarUnauthorized from './navbar/navbarUnauthorized';//Veľké písmeno na začiatku, inak error

function App() {
  const { userLogged } = useContext(UserContext);
  const theme = createTheme({
    palette: {
      primary: {
        light: '#03a9f4',
        main: '#2196f3',
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#000000',
        main: '#000000',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
    typography: {
      /*
      allVariants: {
        fontFamily: 'Nunito',
      },*/
      cardTitle: {
        fontSize: 22,
        textAlign: "center",
        fontWeight: "bold",
      },
      cardBodyTitle: {
        fontWeight: "bold",
      },
      cardBody: {
        display: "flex",
        fontWeight: "bold",
        justifyContent: "center",
      },
    },
    mainBox: {
      ml: 33
    },
    styleBoxSuccess: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      alignItems: "center",
    }
  });

  return (
    <SnackbarProvider maxSnack={2} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} preventDuplicate>
      <ThemeProvider theme={theme}>
        <div style={{ display: 'flex', marginTop: 64 }} >
          {userLogged.auth ? <NavbarAuthorized /> : <NavbarUnauthorized />}

        </div>
      </ThemeProvider>
    </SnackbarProvider >
  );
}

export default App;
