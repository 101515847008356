const Container = { display: "flex", flexDirection: "column" };
const Header = { padding: "10px 20px", textAlign: "center", color: "white" };
const LabelContainer = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};
const Label = { padding: "10px 20px", textAlign: "center" };
const FormContainer = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};
const ErrorMessage = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const BoxSuccess = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    alignItems: "center",
};

export const styles = {
    Container: Container,
    Header: Header,
    LabelContainer: LabelContainer,
    Label: Label,
    ErrorMessage: ErrorMessage,
    FormContainer: FormContainer,
    BoxSuccess: BoxSuccess
}
