import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ambulanciaApi from "../../api/ambulanciaApi";
import 'react-phone-input-2/lib/material.css'
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import PsychologyIcon from '@mui/icons-material/Psychology';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { green } from '@mui/material/colors';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useLocation } from 'react-router-dom';




function EditUser() {

    let navigate = useNavigate();
    const { state } = useLocation();
    const { userInfo } = state || {};
    const { enqueueSnackbar } = useSnackbar();

    //otvorenie modal okna po uspesnej registracii
    const [open, setOpen] = React.useState(false);

    //atribúty pre validáciu formulára priamo počas písania
    const [emailText, setEmailText] = React.useState(userInfo.email);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState("");

    //regexy pre validaciu na klientovi
    const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    const [category, setCategory] = React.useState(userInfo.personCategory);

    const handleChange = (event) => {
        setCategory(event.target.value);
    };


    /*Validácia emailu */
    React.useEffect(() => {
        if (!regexEmail.test(emailText)) {
            setEmailErrorMessage(
                "Neplatný email"
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailText]);

    /*Validácia emailu */
    React.useEffect(() => {
        if (emailText.length < 1 || regexEmail.test(emailText)) {
            setEmailErrorMessage("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailText, emailErrorMessage]);


    /* Registrácia usera s validáciou zo servera*/
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let email = emailText;
        let name = data.get("name");
        let surname = data.get("surname");
        let personCategory = data.get("category");
        //console.log(email, password, uciID, telephoneNumber, confirmPassword);

        try {
            const apiResponse = await ambulanciaApi.put("/admin/user/" + userInfo._id,
                {
                    email,
                    name,
                    surname,
                    personCategory
                },
            );
            if (apiResponse.status === 200) {
                setOpen(false);
                enqueueSnackbar('Údaje používateľa boli aktualizované !', { variant: 'success' });
                navigate("/sprava_uctov");
            }
        }

        catch (error) {
            if (error.response.status === 400) {
                console.error("Api ERROR: ", error.response.data);
                if (error.response.data.message === "validation.email_already_exist")
                    enqueueSnackbar('Zadaný E-mail sa už používa!', { variant: 'error' });
                else
                    enqueueSnackbar('Vyskytla sa chyba!', { variant: 'error' });
            }
            else {
                enqueueSnackbar('Vyskytla sa chyba!', { variant: 'error' });
            }
        }


    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        alignItems: "center",
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <PsychologyIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Úprava používateľa
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl sx={{ display: "flex" }}>
                                <InputLabel id="demo-simple-select-helper-label">Kategória</InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-helper-label"
                                    id="category"
                                    name="category"
                                    value={category}
                                    label="Kategória"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"Doktor"}>Doktor</MenuItem>
                                    <MenuItem value={"Dentálny hygienik"}>Dentálny hygienik</MenuItem>
                                    <MenuItem value={"Zdravotná sestrička"}>Zdravotná sestrička</MenuItem>
                                    <MenuItem value={"Recepcia"}>Recepcia</MenuItem>
                                </Select>

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="name"
                                type="name"
                                required
                                fullWidth
                                id="name"
                                label="Meno"
                                autoFocus
                                defaultValue={userInfo.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                type="surname"
                                id="surname"
                                label="Priezvisko"
                                name="surname"
                                defaultValue={userInfo.surname}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled
                                required
                                fullWidth
                                type="Email"
                                error={!regexEmail.test(emailText) && emailText.length > 0}
                                onChange={(e) => setEmailText(e.target.value)}
                                helperText={emailErrorMessage}
                                value={emailText}
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete='off'
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Uložiť zmeny
                    </Button>
                </Box>
            </Box>
            <div>
                <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Typography id="modal-modal-title" variant="h5" component="h2" align="center">
                            Údaje užívateľa boli aktualizované !
                        </Typography>
                        <Container align="center">
                            <Avatar sx={{ width: 100, height: 100, bgcolor: "white" }}  >
                                <CheckCircleOutlinedIcon sx={{ width: 100, height: 100, color: green[500] }} />
                            </Avatar>
                        </Container>
                    </Box>
                </Modal>
            </div>

        </Container>
    );
}

export default EditUser;