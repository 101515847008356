import * as React from 'react';
import { Container, Typography, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Avatar } from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LockResetIcon from '@mui/icons-material/LockReset';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import SettingsIcon from '@mui/icons-material/Settings';
import ChangePassword from './changePassword';
import PriceList from '../adminPages/priceList';


function Settings() {
    const [openChangePassword, setOpenChangePassword] = React.useState(false);
    const [openChangeTariff, setOpenChangeTariff] = React.useState(false);

    const handleClickChangePassword = () => {
        setOpenChangePassword(!openChangePassword);
    };

    const handleClickChangeTariff = () => {
        setOpenChangeTariff(!openChangeTariff);
    };

    return (
        <Container maxWidth="xl" align="center">
            <Avatar sx={{ mt: 1, bgcolor: 'secondary.main' }}>
                <SettingsIcon />
            </Avatar>
            <Typography variant="h4"> Nastavenia</Typography>
            <List>
                <ListItemButton onClick={handleClickChangePassword}>
                    <ListItemIcon>
                        <LockResetIcon />
                    </ListItemIcon>
                    <ListItemText primary="Zmena hesla" />
                    {openChangePassword ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openChangePassword} timeout="auto" unmountOnExit>
                    <ChangePassword></ChangePassword>
                </Collapse>
                <ListItemButton onClick={handleClickChangeTariff}>
                    <ListItemIcon>
                        <PriceChangeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Nastavenie cenníka" />
                    {openChangeTariff ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openChangeTariff} timeout="auto" unmountOnExit>
                    <PriceList></PriceList>
                </Collapse>
            </List>

        </Container>
    );
};

export default Settings;