import * as React from 'react';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PhotoAlbum from "react-photo-album";


function ImageViewer(props) {

    const photos = [
        { src: props.src, width: 1600, height: 800 }
    ];

    function handleClick() {
        props.func(false)
    }

    return (
        <>
            <div>
                <IconButton aria-label="back" onClick={handleClick} >
                    <CloseIcon />
                </IconButton>
            </div>
            <Container maxWidth="xl">
                <br></br>
                <br></br>
                <PhotoAlbum layout="rows" photos={photos} />
            </Container>

        </>
    );
};

export default ImageViewer;