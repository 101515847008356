import * as React from 'react';
import ambulanciaApi from "../../api/ambulanciaApi";
import { useState, useContext, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../middleware/userContext';

import Container from '@mui/material/Container';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, IconButton, Grid, Box, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ImageViewer from './imageViewer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


function ImageGallery(props) {
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { logoutUserContext } = useContext(UserContext);
    const [imageOpen, setImageOpen] = useState(false);
    const [source, setSource] = useState("");
    const [images, setImages] = useState([]);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [clickedImage, setClickedImage] = React.useState([]);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [originalName, setOriginalName] = useState("");
    const [text, setText] = useState("");




    function handleOpenDelete(image) {
        setOpenDelete(true);
        setClickedImage(image);
    }

    /*
    function downloadBase64File(img) {
        const linkSource = `data:${img.type};base64,${img.photo}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = img.originalName;
        downloadLink.click();
    }
    */

    const deleteImage = async () => {
        setOpenDelete(false);
        try {
            await ambulanciaApi.delete("/user/image/" + clickedImage._id);
            getPatientImages();
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie")
            }
            return
        }
        enqueueSnackbar('Fotografia bola úspešne odstránená !', { variant: 'success' });
    }

    function handleOpenEdit(img) {
        setOpenEdit(true);
        setText(img.text);
        setOriginalName(img.originalName);
        setClickedImage(img);
    }

    const pull_data = (data) => {
        setImageOpen(data);
    }

    function handleClickBack() {
        props.func(false)
    }

    function handleClickImage(src) {
        setImageOpen(true);
        setSource(src)
    }

    const getPatientImages = React.useCallback(async () => {
        try {
            const apiResponseImages = await ambulanciaApi.get("/user/getPatientImages/" + props.userId);
            let tempImages = apiResponseImages.data;
            tempImages.sort((a, b) => new Date(b.date) - new Date(a.date));
            setImages(tempImages);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }, [logoutUserContext, navigate,props.userId]);

    useEffect(() => {
        getPatientImages();
    }, [getPatientImages]);

    const handleSubmitEdit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let originalName = data.get("title");

        try {
            //console.log(doctor, title, nurses, text);
            const apiResponse = await ambulanciaApi.put("/user/image/" + clickedImage._id,
                {
                    originalName, text
                },
            );
            if (apiResponse.status === 200) {
                setOpenEdit(false);
                enqueueSnackbar('Fotografia bola úspešne aktualizovaná !', { variant: 'success' });
            }
        }
        catch (error) {
            enqueueSnackbar('Vyskytla sa chyba!', { variant: 'error' });
        }

        getPatientImages();
        setText("");
        setOriginalName("");
    }

    const handleFileSelected = async (e) => {
        const photo = e.target.files[0];
        const idNumber = props.userId;
        console.log(photo);

        const formData = new FormData();
        formData.append("photo", photo);
        formData.append("idNumber", idNumber);


        try {
            const config = { headers: { 'Content-Type': `multipart/form-data; boundary=${formData._boundary}` } }
            const apiResponse = await ambulanciaApi.post("/user/addImage", formData, config);
            if (apiResponse.status === 201) {
                //setOpenCreateRecord(false);
                enqueueSnackbar('Fotografia bola pridaná do galérie !', { variant: 'success' });
            }
        }
        catch (error) {
            enqueueSnackbar('Vyskytla sa chyba, fotografiu sa nepodarilo nahrať !', { variant: 'error' });
        }
        // console.log("files:", files[0])
        getPatientImages();
    }

    return (
        <>
            {!imageOpen ?
                <>
                    <div>
                        <IconButton aria-label="back" onClick={(handleClickBack)} >
                            <ArrowBackIcon />
                        </IconButton>

                    </div>
                    <Container maxWidth="xl">
                        <br></br>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: "center"
                        }}>
                            <form encType="multipart/form-data">
                                <input hidden accept="image/png" className="input" id="icon-button-file" type="file" onChange={handleFileSelected} />
                                <label htmlFor="icon-button-file">
                                    <IconButton size="large" className="inputbutton" component="span" >
                                        <AddPhotoAlternateIcon fontSize="large" />
                                    </IconButton>
                                </label>
                            </form>
                        </Box>
                    </Container>


                    <Container maxWidth="xl">
                        <br></br>
                        <br></br>
                        {
                            images.length < 1 ? null :
                                <Grid container spacing={2} justifyContent="center">
                                    {images.map((image) => (
                                        <Grid item key={image._id} xs={2} style={{ display: 'flex' }}>
                                            <Card sx={{ width: 300, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                                <CardActionArea onClick={() => handleClickImage(`data:${image.type};base64,${image.image}`)}>
                                                    <CardMedia
                                                        component="img"
                                                        height="140"
                                                        image={`data:${image.type};base64,${image.image}`}
                                                        alt="green iguana"
                                                    />
                                                </CardActionArea>
                                                <CardContent >
                                                    <Typography sx={{ maxHeight: 60, overflow: "hidden" }} gutterBottom variant="h5" component="div">
                                                        {image.originalName}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {image.text}
                                                    </Typography>
                                                </CardContent>

                                                <CardActions>
                                                    <Stack direction="row" alignItems="center" spacing={1}>
                                                        <IconButton aria-label="edit" size="large" onClick={() => handleOpenEdit(image)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton aria-label="delete" size="large" onClick={() => handleOpenDelete(image)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                        {/*  <IconButton aria-label="download" size="large" onClick={() => downloadBase64File(image)}>
                                                            <DownloadIcon />
                                                        </IconButton> */}
                                                    </Stack>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                        }
                        <br></br>
                    </Container>
                </> :
                <ImageViewer state={imageOpen} func={pull_data} src={source} />
            }

            <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
                <DialogTitle>
                    Zmazať obrázok "{clickedImage.originalName}"?
                </DialogTitle>
                <DialogContent>
                    Skutočne chcete zmazať túto položku?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDelete(false)} color="primary">
                        Zrušiť
                    </Button>
                    <Button
                        onClick={deleteImage}
                        color="error"
                        startIcon={<DeleteIcon />}
                    >
                        Zmazať
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openEdit} maxWidth="md" onClose={() => setOpenEdit(false)}>
                <DialogTitle>Editovanie fotografie</DialogTitle>
                <Box component="form" onSubmit={handleSubmitEdit} justifyContent="center">
                    <DialogContent>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} >
                                <TextField
                                    id="title"
                                    name='title'
                                    value={originalName}
                                    fullWidth
                                    onChange={(e) => setOriginalName(e.target.value)}
                                    label="Názov obrázku"
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    id="text"
                                    name='text'
                                    value={text}
                                    fullWidth
                                    onChange={(e) => setText(e.target.value)}
                                    label="Popis"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenEdit(false)}>Zrušiť</Button>
                        <Button type="submit" >Uložiť zmeny </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};

export default ImageGallery;