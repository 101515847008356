import * as React from "react";
import { useContext } from "react";
import { Route, Routes, Link, useNavigate } from 'react-router-dom';

import ambulanciaApi from "../api/ambulanciaApi";
import { UserContext } from '../middleware/userContext';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';

import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';


import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import ContactEmergencyOutlinedIcon from '@mui/icons-material/ContactEmergencyOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Logout from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

//import HomePage from "../pages/homePage";

import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';

import Patients from "../pages/userPages/patients";
import EventCalendar from "../pages/userPages/eventCalendar";
import CashRegister from "../pages/userPages/cashRegister";
import UserManager from "../pages/adminPages/userManager";
import RegisterPage from "../auth/registerPage";
import EditUser from "../pages/adminPages/editUser";
import CreatePatient from "../pages/userPages/createPatient";
import PatientCard from "../pages/userPages/patientCard";
import Patient from "../pages/userPages/patient";
import NotFound from "../pages/notFound";
//import AppSettings from "../pages/adminPages/priceList";
import EditPatient from "../pages/userPages/editPatient";
import PriceList from "../pages/adminPages/priceList";
import CashRecord from "../pages/userPages/cashRecord";
import MainPage from "../pages/userPages/mainPage";
import ChangePassword from "../pages/userPages/changePassword";
import Settings from "../pages/userPages/settings";


function NavbarAuthorized() {
    let navigate = useNavigate();
    const { logoutUserContext } = useContext(UserContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const drawerWidth = 210;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        getUser();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps


    // const [referenceEmail, setReferenceEmail] = React.useState("");
    const [user, setUser] = React.useState("");

    /* Získanie prihláseného usera */
    const getUser = async () => {
        try {
            const apiResponseUser = await ambulanciaApi.get("/user/current");
            if (apiResponseUser.status === 200)
                //  setReferenceEmail(apiResponseUser.data.email);
                setUser(apiResponseUser.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                console.error("TOKEN ERROR: ", error.response.data);
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    };


    /* Odhlásenie */
    const logoutUser = async () => {
        const apiResponseLogOut = await ambulanciaApi.post("/user/signout", { user });
        if (apiResponseLogOut.status === 200) {
            logoutUserContext();
            return navigate("/prihlasenie");
        }
    };

    return (
        <>

            <Box sx={{ display: 'flex' }} >
                <CssBaseline />
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar disableGutters>
                        <Avatar alt="Logo_zub" src={require('../images/zub_logo.png')} component={Link} to="/domov" />
                        <Typography variant="h6" noWrap component="div">
                            Zubná ambulancia
                        </Typography>
                        <Box sx={{ flex: 1, textAlign: 'right' }}>
                            <Tooltip title="Môj účet">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    {/*<Avatar sx={{ width: 32, height: 32 }}>M</Avatar>*/}
                                    <AccountCircle style={{ alignSelf: 'center', width: 40, height: 40 }} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem style={{ pointerEvents: 'none' }}>
                                <Avatar /> {user.name} {user.surname === undefined ? null : user.surname}
                            </MenuItem>
                            <Divider />
                            {
                                /*
                                <MenuItem component={Link} to="/profil">
                                    <ListItemIcon >
                                        <PermIdentityOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    Profil
                                </MenuItem>
                                */
                            }
                            <MenuItem component={Link} to="/nastavenia">
                                <ListItemIcon >
                                    <SettingsIcon fontSize="small" />
                                </ListItemIcon>
                                Nastavenia
                            </MenuItem>
                            <MenuItem onClick={logoutUser}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Odhlásiť sa
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    sx={{
                        anchor: "left",
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },

                    }}
                >
                    <Toolbar />
                    <Box sx={{ overflow: 'auto' }}>
                        <List>
                            <ListItem key="Domov" button component={Link} to="/" disablePadding >
                                <ListItemButton >
                                    <ListItemIcon
                                    >
                                        <HomeOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Domov" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            <ListItem key="Pacienti" button component={Link} to="/pacienti" disablePadding>
                                <ListItemButton>
                                    <ListItemIcon
                                    >
                                        <ContactEmergencyOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Pacienti" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="Kalendar" button component={Link} to="/kalendar" disablePadding>
                                <ListItemButton>
                                    <ListItemIcon
                                    >
                                        <CalendarMonthIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Kalendár" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="Pokladna" button component={Link} to="/pokladna" disablePadding>
                                <ListItemButton>
                                    <ListItemIcon
                                    >
                                        <CurrencyExchangeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Pokladnica" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                        {!user.isAdmin ? null :
                            <React.Fragment>
                                <Divider />
                                <List>
                                    <ListItem key="Sprava_uzivatelov" button component={Link} to="/sprava_uctov" disablePadding >
                                        <ListItemButton >
                                            <ListItemIcon
                                            >
                                                <ManageAccountsIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Správa používateľov" />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </React.Fragment>
                        }
                    </Box>
                </Drawer>

            </Box>


            {/* *****ROUTES FOR LOGGED USER***** */}
            <Routes>
                <Route exact path="/domov" element={<MainPage />} />
                <Route path="/pacienti" element={<Patients />} />
                <Route path="/kalendar" element={<EventCalendar />} />
                <Route path="/pokladna" element={<CashRegister />} />
                <Route path="/pacient" element={<Patient />} />
                <Route path="/sprava_uctov" element={<UserManager />} />
                <Route path="/registracia" element={<RegisterPage />} />
                <Route path="/uprava_pouzivatela" element={<EditUser />} />
                <Route path="/novy_pacient" element={<CreatePatient />} />
                <Route path="/karta_pacienta" element={<PatientCard />} />
                <Route path="/cennik" element={<PriceList />} />
                <Route path="/uprava_pacienta" element={<EditPatient />} />
                <Route path="/pokladnicny_zaznam" element={<CashRecord />} />
                <Route path="/zmena_hesla" element={<ChangePassword />} />
                <Route path="/nastavenia" element={<Settings />} />


                {/* TIETO ROUTY MUSIA BYŤ RENDEROVANÉ AKO POSLEDNÉ!!! */}
                <Route path="/404" element={<NotFound />} />
                <Route path="/" element={<MainPage />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
};

export default NavbarAuthorized;
