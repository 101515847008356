import * as React from 'react';
import { Container, Typography, Grid, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

function NotFound() {
    let navigate = useNavigate();

    return (
        <Container maxWidth="xl">
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={4} sx={{ mt: 15 }}><img alt='notfound' src={require('../images/notFound.jpg')} width="500" height="600" /></Grid>
                <Grid item xs={8} sx={{ mt: 35 }} align="center" >
                    <Typography variant="h1">404 </Typography>
                    <Typography variant="h1"> Stránka nebola nájdená </Typography>
                    <Button variant="outlined" onClick={() => navigate("/")} >Návarat na hlavnú stránku</Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default NotFound;