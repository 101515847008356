import * as React from 'react';
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

import ambulanciaApi from "../../api/ambulanciaApi";
import { UserContext } from '../../middleware/userContext';

import {
    Avatar, Box, Chip, Container, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText,
    Slide, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogTitle,Button
} from '@mui/material';


import AccountCircle from '@mui/icons-material/AccountCircle';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import CakeIcon from '@mui/icons-material/Cake';
import MaleOutlinedIcon from '@mui/icons-material/MaleOutlined';
import FemaleOutlinedIcon from '@mui/icons-material/FemaleOutlined';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Person4Icon from '@mui/icons-material/Person4';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function PatientProfile(props) {
    const [patientData, setPatientData] = useState([]);
    const [openDelete, setOpenDelete] = React.useState(false);
    const { logoutUserContext } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    let navigate = useNavigate();

    const getUser = async () => {
        try {
            const apiResponseUser = await ambulanciaApi.get("/user/getPatientById/" + props.userId);
            if (apiResponseUser.status === 200) {
                setPatientData(apiResponseUser.data[0]);
            }
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    };

    const deletePatient = async () => {
        setOpenDelete(false);
        try {
            await ambulanciaApi.delete("/user/patient/" + patientData._id);
            enqueueSnackbar('Pacient bol úspešne odstránený !', { variant: 'success' });
            navigate("/pacienti")
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie")
            }else{
                enqueueSnackbar('Vyskytla sa chyba ! Pacient nebol odstránený !', { variant: 'error' });
            }
            return
        }
    }


    useEffect(() => {
        getUser();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <Slide direction="right" in={true} mountOnEnter unmountOnExit>
                <Container align="center">
                    <br></br>
                    <Avatar sx={{ width: 100, height: 100, bgcolor: "primary.second" }}  >
                        <AccountCircle style={{ alignSelf: 'center', width: 100, height: 100 }} />
                    </Avatar>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h5" >
                                {patientData.academicDegree}  {patientData.name} {patientData.surname}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} align="center">
                            <Avatar sx={{ width: 20, height: 20, bgcolor: "white" }}  >
                                {patientData.sex === "Žena" ?
                                    <FemaleOutlinedIcon style={{ alignSelf: 'center', width: 20, height: 20, color: "black" }} /> :
                                    <MaleOutlinedIcon style={{ alignSelf: 'center', width: 20, height: 20, color: "black" }} />}

                            </Avatar>
                            <Typography  >
                                {patientData.sex === "Žena" ? "Žena" : "Muž"}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: "center"
                    }}>
                        <IconButton onClick={(e) => navigate("/uprava_pacienta", {
                            state: {
                                patientData: patientData
                            }
                        })} aria-label="delete">
                            <EditIcon fontSize="medium" />
                        </IconButton>
                        <IconButton onClick={() => setOpenDelete(true)} aria-label="delete" >
                            <DeleteIcon fontSize="medium" />
                        </IconButton>
                    </Box>

                    <br></br>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Divider>
                                <Chip
                                    label="Poznámka"
                                    size="medium"
                                    color="success" />
                            </Divider>
                        </Grid>
                        <Grid item xs={12} >
                            <Box
                                sx={{
                                    display: 'inline-flex',
                                    m: 1,
                                    p: 1,
                                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                                    color: (theme) =>
                                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                                    border: '1px solid',
                                    borderColor: (theme) =>
                                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                    borderRadius: 2,
                                    fontSize: '0.875rem',
                                    fontWeight: '700',
                                }}
                            >
                                {patientData.note !== undefined || patientData != null ? patientData.note : "K pacientovi nie je priradená žiadna poznámka"}
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Divider>
                                <Chip
                                    label="Informácie o pacientovi"
                                    size="medium"
                                    color="success" />
                            </Divider>
                            <List
                                sx={{
                                    width: '100%',
                                    maxWidth: 360,
                                    bgcolor: 'background.paper',
                                }}
                            >
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Person4Icon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Ošetrujúci doktor" secondary={patientData.assignedDoctor} />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CakeIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Dátum narodenia" secondary={patientData.birthday !== undefined ? (patientData.birthday).split("T")[0] : null} />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <MoneyOutlinedIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Rodné číslo" secondary={patientData.idNumber} />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <MoneyOutlinedIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Identifikačné číslo EU preukazu" secondary={patientData.idEuNumber} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <Divider>
                                <Chip
                                    label="Kontaktné informácie"
                                    size="medium"
                                    color="success" />
                            </Divider>
                            <List
                                sx={{
                                    width: '100%',
                                    maxWidth: 360,
                                    bgcolor: 'background.paper',
                                }}
                            >
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <FlagCircleIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Krajina" secondary={patientData.country} />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <LocationOnIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Adresa" secondary={patientData.street + " " + patientData.houseNumber + " , " + patientData.postalCode + " " + patientData.city} />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ContactPhoneIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Telefón" secondary={patientData.phone} />
                                </ListItem>

                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AlternateEmailIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Email" secondary={patientData.email} />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Container>
            </Slide>

            <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
                <DialogTitle>
                    Odstániť pacienta {patientData.name + patientData.surname} ?
                </DialogTitle>
                <DialogContent>
                    Skutočne chcete trvalo odstániť pacienta ? Táto akcia sa nedá vrátiť späť.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDelete(false)} color="primary">
                        Zrušiť
                    </Button>
                    <Button
                        onClick={deletePatient}
                        color="error"
                        startIcon={<DeleteIcon />}
                    >
                        Zmazať
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default PatientProfile;