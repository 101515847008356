import * as React from 'react';
import { useContext, useEffect } from "react";
import { UserContext } from '../../middleware/userContext';
import ambulanciaApi from "../../api/ambulanciaApi";
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";

import Container from '@mui/material/Container';
import { Fragment, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { CirclePicker } from 'react-color'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';


require('moment/locale/sk.js')
const localizer = momentLocalizer(moment);

function EventCalendar() {
    const [pickedColor, setPickedColor] = useState("#2196f3");
    const [openEventCreate, setOpenEventCreate] = React.useState(false);
    const [openEventInfo, setOpenEventInfo] = React.useState(false);
    const [selectedDoctorInEvent, setSelectedDoctorInEvent] = React.useState('');
    const [doctors, setDoctors] = React.useState([]);
    const [selectedPatientInEvent, setSelectedPatientInEvent] = React.useState('');
    const [patients, setPatients] = React.useState([]);
    const [startEvent, setStartEvent] = React.useState("");
    const [endEvent, setEndEvent] = React.useState("");
    const [clickedEvent, setClickedEvent] = React.useState([]);
    const [selectedDoctorCalendar, setSelectedDoctorCalendar] = React.useState("Všetky kalednáre");
    const [isDisabled, setIsDisabled] = React.useState(false);



    const { enqueueSnackbar } = useSnackbar();
    const { logoutUserContext } = useContext(UserContext);
    const [user, setUser] = React.useState(""); 
    let navigate = useNavigate();

    const [Events, setEvents] = useState([]);

    const handleClose = (e, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setOpenEventCreate(false);

    };

    function handleOpenEventInfo(e) {
        setOpenEventInfo(true);
        setClickedEvent(e);
        //console.log(clickedEvent);
    }

    function handleSelect(e) {
        setPickedColor("#2196f3");
        if (selectedDoctorCalendar !== "Všetky kalednáre") {
            //let temp = user.name + " " + user.surname + " (" + user.email + ") ";
            let temp;
            doctors.forEach((element) => {
                if (element.email === selectedDoctorCalendar) {
                    temp = element.name + " " + element.surname + " (" + element.email + ") "
                }
            })
            setSelectedDoctorInEvent(temp)
            setIsDisabled(true);
        } else {
            setSelectedDoctorInEvent("")
            setIsDisabled(false);
        }
        setOpenEventCreate(true);
        setEndEvent(e.end);
        setStartEvent(e.start);
    }

    function handleSelectColor(e) {
        setPickedColor(e.hex);
    }

    const getDoctorEvents = React.useCallback(async (email) => {
        try {
            //console.log(x);
            // console.log(selectedDoctorCalendar);
            const apiResponseEvents = await ambulanciaApi.get("/user/getDoctorEvents/" + email);
            let temp = apiResponseEvents.data;
            temp.forEach(element => {
                element.start = moment(element.start).toDate();
                element.end = moment(element.end).toDate();
            });
            setEvents(temp);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }, [logoutUserContext, navigate]);

    const getAllDoctors = async () => {
        try {
            const apiResponseAllDoctors = await ambulanciaApi.get("/user/getAllDoctors");
            setDoctors(apiResponseAllDoctors.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }

    const getAllPatients = async () => {
        try {
            const apiResponseAllPatients = await ambulanciaApi.get("/user/getAllPatients");
            setPatients(apiResponseAllPatients.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }

    const getAllEvents = async () => {
        try {
            const apiResponseAllEvents = await ambulanciaApi.get("/user/getAllEvents");
            let temp = apiResponseAllEvents.data;
            temp.forEach(element => {
                element.start = moment(element.start).toDate();
                element.end = moment(element.end).toDate();
            });
            setEvents(temp);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }

    const deleteEvent = async () => {
        setOpenEventInfo(false);
        let temp = selectedDoctorCalendar;
        setSelectedDoctorCalendar("");
        try {
            await ambulanciaApi.delete("/user/event/" + clickedEvent._id);
            getAllEvents();
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie")

            }
            return
        }
        enqueueSnackbar('Udalosť bola úspešne odstránená !', { variant: 'success' });
        setSelectedDoctorCalendar(temp);
    }


    function getFromBrackets(stringValue) {
        let regExp = /\(([^)]+)\)/;
        let matches = regExp.exec(stringValue);
        return matches[1];
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let idNumber;
        if (selectedPatientInEvent)
            idNumber = getFromBrackets(selectedPatientInEvent);
        let doctorEmail = getFromBrackets(selectedDoctorInEvent);
        let title = data.get("title");
        let color = pickedColor;
        let start = startEvent;
        let end = endEvent;

        //regex for empty or null 
        let regex = /^$|^\s*$/;

        if (regex.test(doctorEmail) || (regex.test(title))) {
            enqueueSnackbar('Vyplňte všetky povinné polia!', { variant: 'warning' });
        } else {
            // console.log(idNumber ,doctorEmail,title,start,end,color);
            try {
                const apiResponse = await ambulanciaApi.post("/user/createEvent",
                    {
                        idNumber, doctorEmail, title, start, end, color
                    },
                );
                if (apiResponse.status === 201) {
                    setOpenEventCreate(false);
                    enqueueSnackbar('Udalosť bola vytvorená !', { variant: 'success' });
                    setPickedColor("#2196f3");
                    setSelectedDoctorInEvent('');
                    setSelectedPatientInEvent('');
                }
            }
            catch (error) {
                enqueueSnackbar('Vyskytla sa chyba, udalosť sa nepodarilo vytvoriť !', { variant: 'error' });
            }
        }
        if (selectedDoctorCalendar === "Všetky kalednáre") {
            getAllEvents();
        } else {
            getDoctorEvents(selectedDoctorCalendar);
        }
    }


    /* Získanie prihláseného usera */
    const getUser = async () => {
        try {
            const apiResponseUser = await ambulanciaApi.get("/user/current");
            if (apiResponseUser.status === 200)
                //  setReferenceEmail(apiResponseUser.data.email);
                setUser(apiResponseUser.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                // console.error("TOKEN ERROR: ", error.response.data);
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    };


    useEffect(() => {
        getUser();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getAllDoctors();
        return () => {
            setDoctors([]);
        };
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getAllPatients();
        return () => {
            setPatients([]);
        };
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    /*
    useEffect(() => {
        getDoctorEvents();
    }, [getDoctorEvents]);
    */

    useEffect(() => {
        // console.log(selectedDoctorCalendar);
        if(user === undefined){

        }
        if (selectedDoctorCalendar !== "") {
            if (selectedDoctorCalendar === "Všetky kalednáre") {
                getAllEvents();
            } else {
                getDoctorEvents(selectedDoctorCalendar);
            }
        }
    }, [selectedDoctorCalendar]); //eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <Container maxWidth="xl">
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                    <Grid item xs={6} >
                        <TextField
                            required
                            id="selected_doctor"
                            name='selected_doctor'
                            value={selectedDoctorCalendar}
                            fullWidth
                            onChange={(e) => setSelectedDoctorCalendar(e.target.value)}
                            select
                            label="Výber kalendára"
                        >
                            <MenuItem value={"Všetky kalednáre"}>Všetky kalednáre</MenuItem>
                            {
                                doctors.map((doctor) => (
                                    <MenuItem key={doctor.email} value={doctor.email}>{doctor.name + " " + doctor.surname + " (" + doctor.email + ") "}</MenuItem>
                                ))
                            }

                        </TextField>
                    </Grid>
                </Grid>
                <Fragment>
                    <br></br>
                    <br></br>
                    <Calendar
                        scrollToTime={moment()
                            .set({ h: 10, m: 0 })
                            .toDate()}
                        localizer={localizer}
                        defaultDate={new Date(Date.now())}
                        defaultView="week"
                        showMultiDayTimes={true}
                        step={15}
                        events={Events}
                        selectable={true}
                        style={{ height: 750, width: '100%', display: "flex" }}
                        eventPropGetter={event => ({
                            style: {
                                backgroundColor: event.color,
                            }
                        })}
                        onSelectSlot={(e) => handleSelect(e)}
                        onSelectEvent={(e) => handleOpenEventInfo(e)}
                        messages={{
                            next: "Ďalej",
                            previous: "Späť",
                            today: "Dnes",
                            month: "Mesiac",
                            week: "Týždeň",
                            day: "Deň"
                        }}
                    />
                </Fragment>

                <Dialog open={openEventCreate} onClose={(e, reason) => handleClose(e, reason)}>
                    <DialogTitle>Nová udalosť</DialogTitle>
                    <Box component="form" onSubmit={handleSubmit} justifyContent="center">
                        <DialogContent>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12} >
                                    <TextField
                                        name="title"
                                        type="Title"
                                        required
                                        fullWidth
                                        id="title"
                                        defaultValue="Nová udalosť"
                                        label="Názov udalosti"
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        disabled={isDisabled}
                                        required
                                        id="selected_doctor"
                                        name='selected_doctor'
                                        value={selectedDoctorInEvent}
                                        fullWidth
                                        onChange={(e) => setSelectedDoctorInEvent(e.target.value)}
                                        select
                                        label="Doktor"
                                    >
                                        {
                                            doctors.map((doctor) => (
                                                <MenuItem key={doctor.email} value={doctor.name + " " + doctor.surname + " (" + doctor.email + ") "}>{doctor.name + " " + doctor.surname + " (" + doctor.email + ") "}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        id="selected_patient"
                                        name='selected_patient'
                                        value={selectedPatientInEvent}
                                        onChange={(event, value) => setSelectedPatientInEvent(value)}
                                        freeSolo
                                        options={patients.map((option) => option.surname + " " + option.name + " (" + option.idNumber + ")")}
                                        renderInput={(params) => <TextField {...params} label="Pacient" />}
                                    />
                                </Grid>
                                <Grid item xs={12} justifyContent="center" style={{ textAlign: "center" }} >
                                    Farba
                                </Grid>
                                <Grid item xs={12} align="center"  >
                                    <CirclePicker onChangeComplete={(e) => handleSelectColor(e)} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Zrušiť</Button>
                            <Button type="submit" >Vytvoriť</Button>
                        </DialogActions>
                    </Box>
                </Dialog>

                <Dialog open={openEventInfo} onClose={() => setOpenEventInfo(false)}>
                    <DialogTitle>
                        Zmazať udalosť "{clickedEvent.title}"?
                    </DialogTitle>
                    <DialogContent>
                        Skutočne chcete zmazať túto udalosť?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenEventInfo(false)} color="primary">
                            Zrušiť
                        </Button>
                        <Button
                            onClick={deleteEvent}
                            color="error"
                            startIcon={<DeleteIcon />}
                        >
                            Zmazať
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    )
}

export default EventCalendar;