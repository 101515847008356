import * as React from 'react';
import Container from '@mui/material/Container';
import { useLocation } from 'react-router-dom';

function PatientCard() {
    const { state } = useLocation();
    return (
        <Container maxWidth="xl">
             { console.log(state.userId)}
            <div className="login-form">
                <div className="form-box solid">
                    <h1>PatientCard</h1>
                </div>
            </div>
        </Container>
    );
};

export default PatientCard;     