import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';



import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import HealthCard from './healthCard';
import DentalCross from './dentalCross';
import PatientProfile from './patientProfile';

function Patient() {
    let navigate = useNavigate();
    const { state } = useLocation();

    const [value, setValue] = React.useState(0);

    function handleClickBack() {
        navigate("/pacienti");
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `action-tab-${index}`,
            'aria-controls': `action-tabpanel-${index}`,
        };
    }

    function renderSwitch(param) {
        switch (param) {
            case 0:
                return <PatientProfile userId={state.userId} />;
            case 1:
                return <HealthCard userId={state.userId} />;
            case 2:
                return <DentalCross userId={state.userId} />;
            default:
                return <PatientProfile userId={state.userId} />;
        }
    }


    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <AppBar position="static" color="default">
                        <Toolbar variant="fullWidth">
                            <IconButton aria-label="back" onClick={handleClickBack} >
                                <ArrowBackIcon />
                            </IconButton>
                            <Tabs
                                sx={{ flex: "auto" }}
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="action tabs example"
                            >
                                <Tab label="Pacient" {...a11yProps(0)} />
                                <Tab label="Zubná karta" {...a11yProps(1)} />
                                <Tab label="Zubný kríž" {...a11yProps(2)} />
                            </Tabs>
                        </Toolbar>
                    </AppBar>
                </Grid>
                {renderSwitch(value)}

                {/*
                {value === 0 ?
                    <Slide direction="right" in={value == 0 ? true : false} mountOnEnter unmountOnExit>

                        <Container align="center">

                            <br></br>
                            <Avatar sx={{ width: 100, height: 100, bgcolor: "primary.second" }}  >
                                <AccountCircle style={{ alignSelf: 'center', width: 100, height: 100 }} />
                            </Avatar>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" >
                                        {patientData.academicDegree}  {patientData.name} {patientData.surname}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} align="center">
                                    <Avatar sx={{ width: 20, height: 20, bgcolor: "white" }}  >
                                        {patientData.Pohlavie === "FEMALE" ?
                                            <FemaleOutlinedIcon style={{ alignSelf: 'center', width: 20, height: 20, color: "black" }} /> :
                                            <MaleOutlinedIcon style={{ alignSelf: 'center', width: 20, height: 20, color: "black" }} />}

                                    </Avatar>
                                    <Typography  >
                                        {patientData.sex === "FEMALE" ? "Žena" : "Muž"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <br></br>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Divider>
                                        <Chip
                                            label="Poznámka"
                                            size="medium"
                                            color="success" />
                                    </Divider>
                                </Grid>
                                <Grid item xs={12} >
                                    <Box
                                        sx={{
                                            display: 'inline-flex',
                                            m: 1,
                                            p: 1,
                                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                                            color: (theme) =>
                                                theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                                            border: '1px solid',
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                            borderRadius: 2,
                                            fontSize: '0.875rem',
                                            fontWeight: '700',
                                        }}
                                    >
                                        {patientData.note !== undefined || patientData != null ? patientData.note : "K pacientovi nie je priradená žiadna poznámka"}
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Divider>
                                        <Chip
                                            label="Informácie o pacientovi"
                                            size="medium"
                                            color="success" />
                                    </Divider>
                                    <List
                                        sx={{
                                            width: '100%',
                                            maxWidth: 360,
                                            bgcolor: 'background.paper',
                                        }}
                                    >
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <CakeIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Ošetrujúci doktor" secondary={patientData.assignedDoctor} />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <CakeIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Dátum narodenia" secondary={patientData.birthday !== undefined ? (patientData.birthday).split("T")[0] : null} />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <GroupsIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Rodné číslo" secondary={patientData.idNumber} />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <Diversity3Icon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Identifikačné číslo EU preukazu" secondary={patientData.idEuNumber} />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={6}>
                                    <Divider>
                                        <Chip
                                            label="Kontaktné informácie"
                                            size="medium"
                                            color="success" />
                                    </Divider>
                                    <List
                                        sx={{
                                            width: '100%',
                                            maxWidth: 360,
                                            bgcolor: 'background.paper',
                                        }}
                                    >
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <MoneyOutlinedIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Krajina" secondary={patientData.country} />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <FlagCircleIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Adresa" secondary={patientData.street + " " + patientData.houseNumber + " , " + patientData.postalCode + " " + patientData.city} />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <EventAvailableOutlinedIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Telefón" secondary={patientData.phone} />
                                        </ListItem>

                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <EventAvailableOutlinedIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Email" secondary={patientData.email} />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                    </Slide>

                    : value === 1 ?
                        <Slide direction="right" in={true} >
                            <Container maxWidth="xl">
                                <h1>Zubná Karta</h1>
                                <Typography paragraph >
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
                                    enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
                                    imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
                                    Convallis convallis tellus id interdum velit laoreet id donec ultrices.
                                    Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
                                    adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
                                    nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
                                    leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
                                    feugiat vivamus at augue. At augue eget arcu dictum varius duis at
                                    consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
                                    sapien faucibus et molestie ac.
                                </Typography>
                            </Container>
                        </Slide>
                        : <></>}
                                    */}
            </Grid>
        </>
    );
};

export default Patient;     