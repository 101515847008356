import "../App.css";
import React, { createContext, useState } from "react";

const UserContext = createContext();

/*-------------------------------------------------------------------------------------*/
/* Zabezpečenie dynamickej zmeny navbaru pri prihlásení, údaje ukladá do localStorage  */
/* - refresh maže všetko v contexte                                                    */
/*-------------------------------------------------------------------------------------*/
const UserProvider = ({ children }) => {
    const [userLogged, setUserLogged] = useState({
        auth: localStorage.getItem('auth')
    });

    const loginUserContext = () => {
        setUserLogged(() => (
            // eslint-disable-next-line no-sequences
            localStorage.setItem('auth', true),
            {
                auth: localStorage.getItem('auth')
            }
        ));

    };

    const logoutUserContext = () => {
        localStorage.clear();
        setUserLogged(() => ({
            auth: false
        }));
    };

    return (
        <UserContext.Provider value={{ userLogged, loginUserContext, logoutUserContext }}>
            {children}
        </UserContext.Provider>
    );
}

export { UserContext, UserProvider };