import * as React from 'react';
import ambulanciaApi from "../../api/ambulanciaApi";
import { useState, useContext, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../middleware/userContext';
import {
    Typography, Container, Button, Box, Grid, Divider, TextField, Autocomplete, Slide
} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import ImageMapperComponent from './imageMapper';


const horneZuby = [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28];
const dolneZuby = [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38];

const DentalCross = (props) => {
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { logoutUserContext } = useContext(UserContext);
    const [clickedTooth, setClickedTooth] = useState("");
    const [openEdit, setOpenEdit] = React.useState(false);
    const [title, setTitle] = React.useState([]);
    const [whole, setWhole] = React.useState("");
    const [top, setTop] = React.useState("");
    const [bottom, setBottom] = React.useState("");
    const [right, setRight] = React.useState("");
    const [left, setLeft] = React.useState("");
    const [mid, setMid] = React.useState("");
    const [crown, setCrown] = React.useState("");
    const [radix, setRadix] = React.useState("");

    const [topFilament, setTopFilament] = React.useState("");
    const [bottomFilament, setBottomFilament] = React.useState("");
    const [rightFilament, setRightFilament] = React.useState("");
    const [leftFilament, setLeftFilament] = React.useState("");
    const [midFilament, setMidFilament] = React.useState("");
    const [crownMaterial, setCrownMaterial] = React.useState("");

    const [dentition, setDentition] = React.useState({});
    const [clickedToothName, setClickedToothName] = React.useState("");
    const [change, setChange] = React.useState(true);

    /*
    const [toothObject, setToothObject] = useState({
        "top": "",
        "mid": "",
        "left": "",
        "bottom": "",
        "right": "",
        "side": ""
    });
    */

    const wholeToothList = [
        { label: 'Extrakcia', symbol: "✚" },
        { label: 'Implantát', symbol: "⇟" },
        { label: 'Neprerezaný zub', symbol: "()" },
        { label: 'Nezaložený zub', symbol: "⦻" },
        { label: 'Fraktúra', symbol: "FR" },
        { label: "Inlay/Overlay", color: "", symbol: "^" },
        { label: 'Dentitio diffcillis', color: "", symbol: "D" },
        { label: 'Gangréna', color: "", symbol: "=" },
        { label: 'Pulpitis', color: "", symbol: "-" },
        { label: 'Periodontis chronica', color: "", symbol: "≡" },
        { label: 'Radix', color: "", symbol: "R" },
        { label: 'Krúžok', color: "", symbol: "▢" },
        { label: 'Zámok', color: "", symbol: "H" },
        { label: 'Paradontálny vačok', color: "", symbol: "▼" }
    ];

    const performanceList = [
        { label: 'Plomba', color: "", symbol: "P" },
        { label: 'Kaz', color: 'rgba(255, 0, 0, 0.7)', symbol: "/" },
        { label: 'Plomba + Kaz', color: "", symbol: "P/" },
        { label: 'Podozrivý kaz', color: "rgba(245, 0, 147,0.7)", symbol: "?" },        
        { label: 'Zubný kameň', color: "", symbol: "ZK" },
        { label: 'Hlboký kaz', color: "", symbol: "//" },
        { label: 'Sekundárny kaz', color: "", symbol: "S/" },
    ];

    const filamentList = [
        { label: 'Provizórna výplň', color: "rgba(0, 72, 25,0.7)" },
        { label: 'Fotokompozit', color: "rgba(133, 149, 1, 0.7)" },
        { label: 'Amalgám', color: "rgba(0, 0, 0, 0.7)" },
        { label: 'Skloionomérny cement', color: "rgba(130, 72, 79,0.7)" },
        { label: 'Keramika', color: "rgba(12, 75, 119,0.7)" },
        { label: "Zlato", color: "rgba(255, 215, 0,0.7)" }
    ];

    const radixList = [
        { label: 'Endo', color: "rgba(255, 0, 0, 0.4)", symbol: "E" },
        { label: 'Nekróza', color: "rgba(0, 0, 0, 0.4)", symbol: "*" },
        { label: 'Koreňová výplň', color: "rgba(133, 149, 1, 0.4)", symbol: "KV" },
        { label: 'Defektná koreňová výplň', color: "rgba(255, 0, 255, 0.4)", symbol: "DKV" }
    ];

    const crownList = [
        { label: 'Medzičlen', color: "white", symbol: "⏜" },
        { label: 'Medzičlen s fazetou', color: "white", symbol: "︽" },
        { label: 'Korunka', color: "white", symbol: "︹" },
        { label: 'Polokorunka', color: "white", symbol: ">" },
        { label: 'Plášťová korunka', color: "white", symbol: "⎴" },
        { label: "Čapová korunka", color: "white", symbol: "⏞" },
        { label: "Fazetová korunka", color: "white", symbol: "︻" },
        { label: "Hall", color: "white", symbol: "HL" },
        { label: "Fazeta", color: "white", symbol: "F" }
    ];

    const crownMaterialList = [
        { label: 'Zirkón', color: "rgba(210, 210, 210, 1)" },
        { label: 'Metalokeramika', color: "rgba(125, 125, 125, 1)" },
        { label: 'Kov', color: "rgba(80, 80, 80, 1)" },
        { label: 'Fazeta', color: "rgba(93, 40, 0, 1)" },
        { label: 'Živica', color: "rgba(68, 85, 0, 1)" },
        { label: "Keramika", color: "rgba(85, 0, 31, 1)" },
        { label: "Plast", color: "rgba(76, 32, 0, 1)" }
    ];

    function findObject(array, label) {
        // let result = new Object({ label: '', color: '' });
        let result = { "label": "", "color": "" }
        if (label.length > 1) {
            array.forEach((element) => {
                if (element.label === label) {
                    result = element;
                }
                //return result;
            })
        }

        return result;
    }

    function handleClickTooth(name) {
        //resetValues();
        //console.log("klik " + name);
        //toothObject.side = side.color;
        setWhole(findObject(wholeToothList, dentition["tooth" + name].whole.name));
        setBottom(findObject(performanceList, dentition["tooth" + name].bottom.name));
        setBottomFilament(findObject(filamentList, dentition["tooth" + name].bottom.filamentName));
        setTop(findObject(performanceList, dentition["tooth" + name].top.name));
        setTopFilament(findObject(filamentList, dentition["tooth" + name].top.filamentName));
        setRight(findObject(performanceList, dentition["tooth" + name].right.name));
        setRightFilament(findObject(filamentList, dentition["tooth" + name].right.filamentName));
        setLeft(findObject(performanceList, dentition["tooth" + name].left.name));
        setLeftFilament(findObject(filamentList, dentition["tooth" + name].left.filamentName));
        setMid(findObject(performanceList, dentition["tooth" + name].mid.name));
        setMidFilament(findObject(filamentList, dentition["tooth" + name].mid.filamentName));
        setCrown(findObject(crownList, dentition["tooth" + name].side.name));
        setCrownMaterial(findObject(crownMaterialList, dentition["tooth" + name].side.filamentName));
        setRadix(findObject(radixList, dentition["tooth" + name].radix.name));
        setTitle(name);
        setOpenEdit(true);
        setClickedToothName("tooth" + name);
        // setClickedTooth(name);
    }

    function handleCloseEdit() {
        setOpenEdit(false);
    }

    /*
    function resetValues() {
        setBottom("");
        setBottomFilament("");
        setTop("");
        setTopFilament("");
        setRight("");
        setRightFilament("");
        setLeft("");
        setLeftFilament("");
        setMid("");
        setMidFilament("");
        setCrown("");
        setCrownMaterial("");
        setRadix("");
    }*/

    //checkne null a undefined, ak nie je null alebo undef vrati objekt inak vrati prazdny string
    function check(obj) {
        if (obj === null || obj === undefined) {
            return "";
        }
        return obj;
    }

    /*
    //checkne null a undefined, ak nie je null alebo undef vrati true inak false
    function isNullOrUndef(obj) {
        return (obj === null || obj === undefined);
    }*/

    const handleSubmitEdit = async (event) => {
        event.preventDefault();
        //const data = new FormData(event.currentTarget);
        dentition[clickedToothName].whole.name = check(whole.label);

        dentition[clickedToothName].mid.name = check(mid.label);
        dentition[clickedToothName].mid.filamentName = check(midFilament.label);

        dentition[clickedToothName].top.name = check(top.label);
        dentition[clickedToothName].top.filamentName = check(topFilament.label);

        dentition[clickedToothName].left.name = check(left.label);
        dentition[clickedToothName].left.filamentName = check(leftFilament.label);

        dentition[clickedToothName].right.name = check(right.label);
        dentition[clickedToothName].right.filamentName = check(rightFilament.label);

        dentition[clickedToothName].bottom.name = check(bottom.label);
        dentition[clickedToothName].bottom.filamentName = check(bottomFilament.label);

        dentition[clickedToothName].side.name = check(crown.label);
        dentition[clickedToothName].side.filamentName = check(crownMaterial.label);

        dentition[clickedToothName].radix.name = check(radix.label);

        dentition[clickedToothName].whole.actualSymbol = check(whole.symbol);
        dentition[clickedToothName].mid.actualSymbol = check(mid.symbol);
        dentition[clickedToothName].top.actualSymbol = check(top.symbol);
        dentition[clickedToothName].bottom.actualSymbol = check(bottom.symbol);
        dentition[clickedToothName].left.actualSymbol = check(left.symbol);
        dentition[clickedToothName].right.actualSymbol = check(right.symbol);
        dentition[clickedToothName].side.actualSymbol = check(crown.symbol);
        dentition[clickedToothName].radix.actualSymbol = check(radix.symbol);



        if (midFilament === "") {
            dentition[clickedToothName].mid.actualColor = check(mid.color);
        } else {
            dentition[clickedToothName].mid.actualColor = check(midFilament.color);
        }

        if (topFilament === "") {
            dentition[clickedToothName].top.actualColor = check(top.color);
        } else {
            dentition[clickedToothName].top.actualColor = check(topFilament.color);
        }

        if (bottomFilament === "") {
            dentition[clickedToothName].bottom.actualColor = check(bottom.color);
        } else {
            dentition[clickedToothName].bottom.actualColor = check(bottomFilament.color);
        }

        if (leftFilament === "") {
            dentition[clickedToothName].left.actualColor = check(left.color);
        } else {
            dentition[clickedToothName].left.actualColor = check(leftFilament.color);
        }

        if (rightFilament === "") {
            dentition[clickedToothName].right.actualColor = check(right.color);
        } else {
            dentition[clickedToothName].right.actualColor = check(rightFilament.color);
        }

        dentition[clickedToothName].radix.actualColor = check(radix.color);

        console.log(crownMaterial.label);
        if (crown.label !== "") {
            if (crownMaterial.label === "" || crownMaterial.label === undefined) {
                dentition[clickedToothName].side.actualColor = check(crown.color);
                dentition[clickedToothName].top.actualColor = check(crown.color);
                dentition[clickedToothName].bottom.actualColor = check(crown.color);
                dentition[clickedToothName].mid.actualColor = check(crown.color);
                dentition[clickedToothName].right.actualColor = check(crown.color);
                dentition[clickedToothName].left.actualColor = check(crown.color);
            } else {
                dentition[clickedToothName].side.actualColor = check(crownMaterial.color);
                dentition[clickedToothName].top.actualColor = check(crownMaterial.color);
                dentition[clickedToothName].bottom.actualColor = check(crownMaterial.color);
                dentition[clickedToothName].mid.actualColor = check(crownMaterial.color);
                dentition[clickedToothName].right.actualColor = check(crownMaterial.color);
                dentition[clickedToothName].left.actualColor = check(crownMaterial.color);
            }
        } else {
            dentition[clickedToothName].side.actualColor = "";
        }
        const tempDentition = dentition;

        try {
            const apiResponse = await ambulanciaApi.put("/user/dentition/" + props.userId,
                {
                    tempDentition
                }
            );
            if (apiResponse.status === 201) {
                setOpenEdit(false);
                enqueueSnackbar('Zubný kríž bol úspešne aktualizovaný !', { variant: 'success' });
            }
        }
        catch (error) {
            enqueueSnackbar('Vyskytla sa chyba!', { variant: 'error' });
        }

        setClickedTooth(title);
        setChange(!change);

    }


    useEffect(() => {
        if (!(mid.label === 'Plomba + Kaz' || mid.label === 'Plomba')) {
            setMidFilament("");
        }
    }, [mid]);

    useEffect(() => {
        if (!(left.label === 'Plomba + Kaz' || left.label === 'Plomba')) {
            setLeftFilament("");
        }
    }, [left]);

    useEffect(() => {
        if (!(right.label === 'Plomba + Kaz' || right.label === 'Plomba')) {
            setRightFilament("");
        }
    }, [right]);

    useEffect(() => {
        if (!(top.label === 'Plomba + Kaz' || top.label === 'Plomba')) {
            setTopFilament("");
        }
    }, [top]);

    useEffect(() => {
        if (!(bottom.label === 'Plomba + Kaz' || bottom.label === 'Plomba')) {
            setBottomFilament("");
        }
    }, [bottom]);

    useEffect(() => {
        if (crown.label === "") {
            setCrownMaterial("");
        }
    }, [crown]);


    const getDentition = React.useCallback(async () => {
        try {
            const apiResponseDentition = await ambulanciaApi.get("/user/getPatientDentition/" + props.userId);
            //console.log(apiResponseDentition.data);
            if (apiResponseDentition.data.length === 0) {
                fillDentition();
            } else {
                // console.log(apiResponseDentition.data[0].dentition[0]);
                setDentition(apiResponseDentition.data[0].dentition[0]);
            }
            //setDentition([]);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }

    }, [logoutUserContext, navigate, props.userId]);

    useEffect(() => {
        getDentition();
    }, [getDentition]);

    function fillDentition() {
        let tempArr = horneZuby.concat(dolneZuby);
        let tempDentition = {};
        tempArr.forEach(element => {
            tempDentition["tooth" + element] = {
                "whole": { name: "", actualSymbol: "" },
                "top": { name: "", filamentName: "", actualColor: "", actualSymbol: "" },
                "mid": { name: "", filamentName: "", actualColor: "", actualSymbol: "" },
                "left": { name: "", filamentName: "", actualColor: "", actualSymbol: "" },
                "bottom": { name: "", filamentName: "", actualColor: "", actualSymbol: "" },
                "right": { name: "", filamentName: "", actualColor: "", actualSymbol: "" },
                "side": { name: "", filamentName: "", actualColor: "", actualSymbol: "" },
                "radix": { name: "", filamentName: "", actualColor: "", actualSymbol: "" },
            };
        });
        setDentition(tempDentition);
    }

    /*
    useEffect(() => {
        handleClickT(clickedTooth);
    }, [handleClickT]);
    */

    function getSymbols(toothObject) {
        // console.log(toothObject);
        let string = "";
        if (toothObject.whole.actualSymbol)
            string += toothObject.whole.actualSymbol + " ";
        if (toothObject.mid.actualSymbol)
            string += toothObject.mid.actualSymbol + " ";
        if (toothObject.top.actualSymbol)
            string += toothObject.top.actualSymbol + " ";
        if (toothObject.bottom.actualSymbol)
            string += toothObject.bottom.actualSymbol + " ";
        if (toothObject.left.actualSymbol)
            string += toothObject.left.actualSymbol + " ";
        if (toothObject.right.actualSymbol)
            string += toothObject.right.actualSymbol + " ";
        if (toothObject.side.actualSymbol)
            string += toothObject.side.actualSymbol + " ";
        if (toothObject.radix.actualSymbol)
            string += toothObject.radix.actualSymbol + " ";
        return string;
        //return toothObject.mid.actualSymbol + toothObject.top.actualSymbol + toothObject.bottom.actualSymbol + toothObject.left.actualSymbol + toothObject.right.actualSymbol + toothObject.side.actualSymbol + toothObject.radix.actualSymbol;
    }


    return (
        <>
            {Object.keys(dentition).length > 1 ?
                <Slide direction="right" in={true} mountOnEnter unmountOnExit>
                    <Container maxWidth="xl" align="center" >
                        <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{ mt: 10 }}>
                            {
                                horneZuby.map((name, index) => (
                                    <Grid key={index + "top"} item >

                                        <Box
                                            onClick={() => handleClickTooth(name, name)}
                                            //   border={1}
                                            borderColor="grey.500"
                                            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end' }}
                                            justifyContent="flex-end"


                                        //p={1}
                                        //m={1}
                                        >

                                            <Typography variant="h6" style={{
                                                width: 80,
                                                wordBreak: 'break-word', height: 100, alignItems: 'center',
                                                display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'
                                            }}>
                                                {getSymbols(dentition["tooth" + name])}
                                            </Typography>

                                            <ImageMapperComponent
                                                change={change}
                                                actualyClickedTooth={clickedTooth}
                                                toothObject={dentition["tooth" + name]}
                                                name={name + "_side"}
                                                key={name + "_side"}
                                                src={require('../../images/side/' + name + "_side.png")}
                                                tooth={name}
                                                orientation={"side"} />

                                            <ImageMapperComponent
                                                change={change}
                                                actualyClickedTooth={clickedTooth}
                                                toothObject={dentition["tooth" + name]}
                                                name={name + "_top"}
                                                key={name + "_top"}
                                                src={require('../../images/top/' + name + "_top.png")}
                                                tooth={name}
                                                orientation={"top"} />

                                            <Typography variant="h6" gutterBottom>
                                                {name}
                                            </Typography>

                                        </Box>
                                        <br></br>
                                    </Grid>
                                ))}
                        </Grid>
                        <Divider />
                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                            {dolneZuby.map((name, index) => (
                                <Grid key={index + "top"} item >
                                    <br></br>
                                    <Box
                                        onClick={() => handleClickTooth(name)}
                                        //border={1}
                                        borderColor="grey.500"
                                        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}
                                        justifyContent="flex-end"
                                    //p={1}
                                    //m={1}
                                    >
                                        <Typography variant="h6" gutterBottom>
                                            {name}
                                        </Typography>
                                        <ImageMapperComponent
                                            change={change}
                                            actualyClickedTooth={clickedTooth}
                                            toothObject={dentition["tooth" + name]}
                                            name={name + "_top"}
                                            key={name + "_top"}
                                            src={require('../../images/top/' + name + "_top.png")}
                                            tooth={name}
                                            orientation={"top"} />
                                        <ImageMapperComponent
                                            change={change}
                                            actualyClickedTooth={clickedTooth}
                                            toothObject={dentition["tooth" + name]}
                                            name={name + "side"}
                                            key={name + "_side"}
                                            src={require('../../images/side/' + name + "_side.png")}
                                            tooth={name}
                                            orientation={"side"} />
                                        <Typography variant="h6" style={{
                                            width: 80,
                                            wordBreak: 'break-word', height: 100, alignItems: 'center',
                                            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'
                                        }}>
                                            {getSymbols(dentition["tooth" + name])}
                                        </Typography>
                                    </Box>
                                    <br></br>
                                </Grid>
                            ))}
                        </Grid>

                    </Container>
                </Slide>
                : null}

            <Dialog open={openEdit} maxWidth="md" onClose={handleCloseEdit}>
                <DialogTitle>Zub č.{title}</DialogTitle>
                <Box component="form" onSubmit={handleSubmitEdit} justifyContent="center">
                    <DialogContent>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={3} >
                                <Typography variant="h6" gutterBottom>
                                    Celý zub
                                </Typography>
                            </Grid>
                            <Grid item xs={9} >
                                <Autocomplete
                                    freeSolo
                                    onChange={whole === null ? setWhole({ "label": "", "symbol": "" }) : (event, value) => setWhole(value)}
                                    // isOptionEqualToValue={(option, value) => option.label === value.label}
                                    key={Math.random()}
                                    disablePortal
                                    id="whole"
                                    options={wholeToothList}
                                    value={whole}
                                    //onChange={}
                                    renderInput={(params) => <TextField {...params} label="Stav" />}
                                />
                            </Grid>

                            <Grid item xs={3} >
                                <Typography variant="h6" gutterBottom>
                                    Okluzálna plocha
                                </Typography>
                            </Grid>
                            <Grid item xs={5} >
                                <Autocomplete
                                    freeSolo
                                    onChange={mid === null ? setMid({ "label": "", "color": "" }) : (event, value) => setMid(value)}
                                    // isOptionEqualToValue={(option, value) => option.label === value.label}
                                    key={Math.random()}
                                    disablePortal
                                    id="mid"
                                    options={performanceList}
                                    value={mid}
                                    //onChange={}
                                    renderInput={(params) => <TextField {...params} label="Stav" />}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <Autocomplete
                                    freeSolo
                                    onChange={midFilament === null ? setMidFilament({ "label": "", "color": "" }) : (event, value) => setMidFilament(value)}
                                    // isOptionEqualToValue={(option, value) => option.label === value.label}
                                    disabled={(mid === undefined || mid === null) ? true : ((mid.label === 'Plomba + Kaz' || mid.label === 'Plomba') ? false : true)}
                                    key={Math.random()}
                                    disablePortal
                                    id="midFill"
                                    options={filamentList}
                                    value={midFilament}
                                    //onChange={(event, value) => setMidFilament(value)}
                                    renderInput={(params) => <TextField {...params} label="Výplň" />}
                                />
                            </Grid>
                            <Grid item xs={3} >
                                <Typography variant="h6" gutterBottom>
                                    Distálna plocha
                                </Typography>
                            </Grid>
                            <Grid item xs={5} >
                                <Autocomplete
                                    freeSolo
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    key={Math.random()}
                                    disablePortal
                                    id="left"
                                    options={performanceList}
                                    value={left}
                                    onChange={left === null ? setLeft({ "label": "", "color": "" }) : (event, value) => setLeft(value)}
                                    renderInput={(params) => <TextField {...params} label="Stav" />}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <Autocomplete
                                    freeSolo
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    key={Math.random()}
                                    disabled={(left === undefined || left === null) ? true : ((left.label === 'Plomba + Kaz' || left.label === 'Plomba') ? false : true)}
                                    disablePortal
                                    id="leftFill"
                                    options={filamentList}
                                    value={leftFilament}
                                    onChange={leftFilament === null ? setLeftFilament({ "label": "", "color": "" }) : (event, value) => setLeftFilament(value)}
                                    renderInput={(params) => <TextField {...params} label="Výplň" />}
                                />
                            </Grid>
                            <Grid item xs={3} >
                                <Typography variant="h6" gutterBottom>
                                    Meziálna plocha
                                </Typography>
                            </Grid>
                            <Grid item xs={5} >
                                <Autocomplete
                                    freeSolo
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    key={Math.random()}
                                    disablePortal
                                    id="right"
                                    options={performanceList}
                                    value={right}
                                    onChange={right === null ? setRight({ "label": "", "color": "" }) : (event, value) => setRight(value)}
                                    renderInput={(params) => <TextField {...params} label="Stav" />}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <Autocomplete
                                    freeSolo
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    key={Math.random()}
                                    disabled={(right === undefined || right === null) ? true : ((right.label === 'Plomba + Kaz' || right.label === 'Plomba') ? false : true)}
                                    disablePortal
                                    id="rightFill"
                                    options={filamentList}
                                    value={rightFilament}
                                    onChange={rightFilament === null ? setRightFilament({ "label": "", "color": "" }) : (event, value) => setRightFilament(value)}
                                    renderInput={(params) => <TextField {...params} label="Výplň" />}
                                />
                            </Grid>
                            <Grid item xs={3} >
                                <Typography variant="h6" gutterBottom>
                                    Vestibulárna plocha
                                </Typography>
                            </Grid>
                            <Grid item xs={5} >
                                <Autocomplete
                                    freeSolo
                                    key={Math.random()}
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    disablePortal
                                    id="bottom"
                                    options={performanceList}
                                    value={bottom}
                                    onChange={bottom === null ? setBottom({ "label": "", "color": "" }) : (event, value) => setBottom(value)}
                                    renderInput={(params) => <TextField {...params} label="Stav" />}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <Autocomplete
                                    freeSolo
                                    key={Math.random()}
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    disabled={(bottom === undefined || bottom === null) ? true : ((bottom.label === 'Plomba + Kaz' || bottom.label === 'Plomba') ? false : true)}
                                    disablePortal
                                    id="bottomFill"
                                    options={filamentList}
                                    value={bottomFilament}
                                    onChange={bottomFilament === null ? setBottomFilament({ "label": "", "color": "" }) : (event, value) => setBottomFilament(value)}
                                    renderInput={(params) => <TextField {...params} label="Výplň" />}
                                />
                            </Grid>
                            <Grid item xs={3} >
                                <Typography variant="h6" gutterBottom>
                                    Palatiálna plocha
                                </Typography>
                            </Grid>
                            <Grid item xs={5} >
                                <Autocomplete
                                    freeSolo
                                    key={Math.random()}
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    disablePortal
                                    id="top"
                                    options={performanceList}
                                    value={top}
                                    onChange={top === null ? setTop({ "label": "", "color": "" }) : (event, value) => setTop(value)}
                                    renderInput={(params) => <TextField {...params} label="Stav" />}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <Autocomplete
                                    freeSolo
                                    key={Math.random()}
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    disabled={(top === undefined || top === null) ? true : ((top.label === 'Plomba + Kaz' || top.label === 'Plomba') ? false : true)}
                                    disablePortal
                                    id="topFill"
                                    options={filamentList}
                                    value={topFilament}
                                    onChange={topFilament === null ? setTopFilament({ "label": "", "color": "" }) : (event, value) => setTopFilament(value)}
                                    renderInput={(params) => <TextField {...params} label="Výplň" />}
                                />
                            </Grid>
                            <Grid item xs={3} >
                                <Typography variant="h6" gutterBottom>
                                    Korunka
                                </Typography>
                            </Grid>
                            <Grid item xs={5} >
                                <Autocomplete
                                    freeSolo
                                    key={Math.random()}
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    disablePortal
                                    id="crown"
                                    options={crownList}
                                    value={crown}
                                    onChange={crown === null ? setCrown({ "label": "", "color": "" }) : (event, value) => setCrown(value)}
                                    renderInput={(params) => <TextField {...params} label="Stav" />}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <Autocomplete
                                    freeSolo
                                    key={Math.random()}
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    disablePortal
                                    id="crownMaterial"
                                    options={crownMaterialList}
                                    value={crownMaterial}
                                    disabled={(crown === undefined || crown === null || crown.label === "") ? true : false}
                                    onChange={crownMaterial === null ? setCrownMaterial({ "label": "", "color": "" }) : (event, value) => setCrownMaterial(value)}
                                    renderInput={(params) => <TextField {...params} label="Materiál" />}
                                />
                            </Grid>
                            <Grid item xs={3} >
                                <Typography variant="h6" gutterBottom>
                                    Koreň
                                </Typography>
                            </Grid>
                            <Grid item xs={9} >
                                <Autocomplete
                                    freeSolo
                                    key={Math.random()}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={radixList}
                                    value={radix}
                                    onChange={radix === null ? setRadix({ "label": "", "color": "" }) : (event, value) => setRadix(value)}
                                    renderInput={(params) => <TextField {...params} label="Stav" />}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseEdit}>Zrušiť</Button>
                        <Button type="submit" >Uložiť zmeny </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
};

export default DentalCross;

