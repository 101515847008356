import * as React from 'react';
import { useSnackbar } from 'notistack';
//import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ambulanciaApi from "../../api/ambulanciaApi";
//import { UserContext } from '../../middleware/userContext';
import { DataGrid } from '@mui/x-data-grid';

import { Grid, AppBar, IconButton, Toolbar, Container, Typography, Box, Divider, TextField, Checkbox, Stack } from '@mui/material';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import "../../fonts/Amiko-Regular-normal"

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';


function CashRecord() {
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    //const { logoutUserContext } = useContext(UserContext);
    const { state } = useLocation();
    const record = state.record;
    const patient = record.patient[0];
    const [docNumber, setDocNumber] = React.useState(record.docNumber !== undefined ? record.docNumber : "");
    const [payed, setPayed] = React.useState(record.payed !== undefined ? record.payed : false);

    const columns = [
        { field: 'code', headerName: 'Kód výkonu', flex: 1, align: "center", headerAlign: 'center', },
        { field: 'name', headerName: 'Názov výkonu', flex: 3, align: "center", headerAlign: 'center', },
        {
            field: "price",
            headerName: "Cena",
            flex: 2,
            align: "center", headerAlign: 'center',
            renderCell: (params) => {
                let temp = params.row.price + ",00 €";
                return <div className="rowitem">{temp}</div>;
            },
        },
    ];


    function formatedDate() {
        let date = new Date(record.date);
        return date.getDate() + ". " + date.toLocaleDateString('sk-SK', { month: 'long' }) + "\n" + date.getFullYear();
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (docNumber === "" && payed === true) {
            enqueueSnackbar('Pre označenie zaplatenia dokladu musí byť vyplnené číslo dokladu !', { variant: 'warning' });
        } else {

            try {
                const apiResponse = await ambulanciaApi.put("/user/cashRecord/" + record._id,
                    {
                        docNumber, payed
                    },
                );
                if (apiResponse.status === 200) {
                    enqueueSnackbar('Pokladničný záznam bol úspešne aktualizovaný !', { variant: 'success' });
                }
            }
            catch (error) {
                enqueueSnackbar('Vyskytla sa chyba!', { variant: 'error' });
            }
        }

    }

    const handleChange = (event) => {
        setPayed(event.target.checked);
    };

    const handlePrint = async (event) => {
        if (docNumber === "") {
            enqueueSnackbar('Pre vytvorenie prehľadu ošetrenia musí byť vyplnené číslo dokladu !', { variant: 'warning' });
        } else {
            handleSubmit(event);
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "portrait"; // portrait or landscape

            const marginLeft = 40;

            const doc = new jsPDF(orientation, unit, size);


            doc.setFontSize(15);
            doc.setFont('Amiko-Regular', 'normal');
            let date = new Date(record.date);
            let date2 = new Date();
            let datum1 = "Zo dňa : " + date.getDate() + "." + date.getMonth() + "." + date.getFullYear();
            let datum2 = "Vystavené : " + date2.getDate() + "." + date2.getMonth() + "." + date2.getFullYear();

            const title = "Prehľad ošetrenia  " + docNumber;
            let odberatel = patient.name + patient.surname;
            let rodneCislo = "R.Č. : " + patient.idNumber;
            let pacientAdresa = patient.street + " " + patient.houseNumber + ", " + patient.postalCode + " " + patient.city;

            let dodavatel = "Zubná ambulancia s.r.o.";
            let icoDic = "IČO: 54299403, DIČ: 1125228181"
            let kzpPzs = "KZP: 465561XXX, PZS: 2132131XXX"
            let dodavatelAdresa = "Horná 5447/42, 038 61 Vrútky";

            const inform = "V súlade § 6 zákona č. 576/2004 Z.z. o zdravotnej starostlivosti,"
                + "bol informovaný o rozsahu, druhu, podmienkach i cene stomatol. \nbodu podľa poisťovne a skutočnej"
                + " cene. Na základe toho súhlasí s príplatkom k vybraným stomatologickým výkonom.";


            const headers = [["Kód výkonu", "Názov výkonu", "Cena"]];

            const data = record.tariffs.map(tariff => [tariff.code, tariff.name, tariff.price + ",00 €"]);

            let content = {
                startY: 160,
                head: headers,
                body: data,
                //theme: 'plain', toto neskôr odkomentovať pre úsporu tlačiarne
                styles: {
                    font: "Amiko-Regular"
                }
            };

            doc.text(title, marginLeft, 40);
            doc.setFontSize(10);
            doc.text(datum1, marginLeft, 55);
            doc.text(datum2, marginLeft, 65);
            doc.rect(40, 70, 515, 60, 'S');

            doc.text("Platca", marginLeft + 10, 85);
            doc.text(odberatel, marginLeft + 10, 98);
            doc.text(rodneCislo, marginLeft + 10, 111);
            doc.text(pacientAdresa, marginLeft + 10, 124);

            doc.text(dodavatel, marginLeft + 300, 85);
            doc.text(icoDic, marginLeft + 300, 98);
            doc.text(kzpPzs, marginLeft + 300, 111);
            doc.text(dodavatelAdresa, marginLeft + 300, 124);

            doc.setFontSize(8);
            doc.text(inform, marginLeft, 140);
            doc.autoTable(content);
            doc.setFontSize(10);


            const text = 'Celková suma : ' + record.totalPrice + ",00 €";
            const podpisPlatcu = "..............................................\n          Podpis platcu";
            const podpisPrijemcu = "..............................................\n         Podpis príjemcu";

            const textWidth = doc.getTextDimensions(text).w;

            // Umiestnenie textu na správne miesto
            const tableBottomY = doc.autoTable.previous.finalY;
            const textX = marginLeft;
            const textY = tableBottomY + 30;
            doc.text(text, textX, textY);
            doc.text(podpisPlatcu, textX + 50, textY + 100);
            doc.text(podpisPrijemcu, textX + 350, textY + 100);
            // Zarámovanie textu
            const borderWidth = 1; // hrúbka rámu v mm
            const borderHeight = 20; // výška rámu v mm
            const borderX = textX - borderWidth; // umiestnenie rámu k ľavému okraju
            const borderY = textY - 14;
            doc.rect(borderX, borderY, textWidth + 2 * borderWidth, borderHeight, 'S');


            // doc.text("Cena spolu : ", marginLeft, 40);
            // doc.save(docNumber + "_pokladnicny_zaznam.pdf")
            doc.autoPrint();
            window.open(doc.output('bloburl'), '_blank');
        }

    };

    return (
        <>
            <Box sx={{ width: '100%' }}>

                <AppBar position="static" color="default">
                    <Toolbar variant="fullWidth">
                        <IconButton aria-label="back" onClick={() => navigate("/pokladna")} >
                            <ArrowBackIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Container maxWidth="xl" align="center">
                    <h1>Pokladničný záznam</h1>
                    <Grid container sx={{ mt: 2 }} columns={15} spacing={2} >
                        <Grid item xs={3}  >
                            <Typography variant="h5">Dátum ošetrenia</Typography>
                            <Typography variant="h6">
                                {formatedDate()}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}  >
                            <Typography variant="h5">Pacient</Typography>
                            <Typography variant="h6">{patient.academicDegree + " " + patient.name + " " + patient.surname}</Typography>
                        </Grid>
                        <Grid item xs={3}  >
                            <Typography variant="h5">Doktor</Typography>
                            <Typography variant="h6">{state.record.doctor.replace(/ *\([^)]*\) */g, "")}</Typography>
                        </Grid>
                        <Grid item xs={2}  >
                            <Typography variant="h5">Zaplatené</Typography>
                            <Checkbox
                                checked={payed}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}  >

                            <TextField
                                id="docNumber"
                                name='docNumber'
                                value={docNumber || ''}
                                fullWidth
                                onChange={(e) => setDocNumber(e.target.value)}
                                label="Číslo dokladu"
                            />

                            <IconButton aria-label="back" sx={{ ml: 1 }} onClick={handleSubmit} >
                                <SaveIcon fontSize="large" />
                            </IconButton>
                        </Grid>

                    </Grid>
                    <Divider></Divider>

                    <Box sx={{ display: 'flex', height: 350, width: '100%', mt: 3 }}>
                        <DataGrid
                            rows={record.tariffs}
                            columns={columns}
                            getRowId={(rows) => rows._id}
                            //checkboxSelection
                            disableSelectionOnClick
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        Pokladnica je prázdna.
                                    </Stack>
                                ),
                                NoResultsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        Zadanému filtru nevyhovuje žiadny záznam
                                    </Stack>
                                )
                            }}
                            experimentalFeatures={{ newEditingApi: true }}
                        />
                    </Box>

                </Container>
                <Container maxWidth="xl">
                    <IconButton aria-label="back" onClick={handlePrint} >
                        <PrintIcon />
                    </IconButton>
                </Container>
            </Box>


        </>
    );
};

export default CashRecord;