import * as React from 'react';
import { useState, useContext, useEffect } from "react";
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';


import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import ambulanciaApi from "../../api/ambulanciaApi";
import { UserContext } from '../../middleware/userContext';
import { DataGrid } from '@mui/x-data-grid';

import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


function CashRegister() {
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { logoutUserContext } = useContext(UserContext);
    const [rows, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [deletedRecord, setDeletedRecord] = useState([]);
    //const [cashRecords, setCashRecords] = useState([]);


    const [open, setOpen] = React.useState(false);


    const columns = [
        // { field: 'name', headerName: 'Meno', flex: 1 },
        //{ field: 'surname', headerName: 'Priezvisko', flex: 1 },
        // { field: 'personCategory', headerName: 'Kategória', flex: 1 },
        {
            field: "name",
            headerName: "Pacient",
            flex: 2,
            align: "center", headerAlign: 'center',
            renderCell: (params) => {
                let temp = params.row.patient[0].name + " " + params.row.patient[0].surname;
                return <div className="rowitem">{temp}</div>;
            },
        },
        { field: 'doctor', headerName: 'Doktor', flex: 2, align: "center", headerAlign: 'center', },
        {
            field: "createdBy",
            flex: 2,
            headerName: "Vystavil",
            align: "center", headerAlign: 'center',
            renderCell: (params) => {
                //  console.log(params);
                let temp = "";
                if (params.row.user[0].surname === undefined) {
                    temp = params.row.user[0].name
                } else {
                    temp = params.row.user[0].name + " " + params.row.user[0].surname;
                }
                return temp;
            },
        },
        { field: 'docNumber', headerName: 'Číslo dokladu', flex: 1, align: "center", headerAlign: 'center', },
        {
            field: "payed",
            width: 100,
            headerName: "Zaplatené",
            //align: "center",
            align: "center",
            headerAlign: 'center',
            renderCell: (params) => {
                //  console.log(params);
                return <div>{params.row.payed === false ? <Chip variant="outlined" color="error" label="✖" /> : <Chip display="flex" variant="outlined" color="success" label="✔" />}</div>;
            },
        },
        {
            field: 'totalPrice', headerName: 'Suma', width: 100, align: "center", headerAlign: 'center',
            renderCell: (params) => {
                return params.row.totalPrice + " €";
            }
        },
        {
            field: 'action',
            headerName: 'Akcia',
            width: 120,
            sortable: false,
            disableClickEventBubbling: true,
            headerAlign: 'center',
            align: "center",

            renderCell: (params) => {
                const onClickDelete = () => {
                    const currentRow = params.row;
                    setDeletedRecord(currentRow);
                    setOpen(true);
                };

                const onClickEdit = () => {
                    const record = params.row;
                    navigate("/pokladnicny_zaznam", { state: { record } });
                };

                return (
                    <Stack direction="row" spacing={1}>
                        <IconButton onClick={(e) => onClickEdit()} aria-label="delete">
                            <EditIcon fontSize="medium" />
                        </IconButton>
                        <IconButton onClick={() => onClickDelete()} aria-label="delete" >
                            <DeleteIcon fontSize="medium" />
                        </IconButton>
                    </Stack>
                );
            },
        },
    ];


    const getAllCashRecords = React.useCallback(async () => {
        try {
            const apiResponseCashRecords = await ambulanciaApi.get("/user/getAllCashRecords");
            //setCashRecords(apiResponseCashRecords.data);
            setRows(apiResponseCashRecords.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }, [logoutUserContext, navigate]);

    useEffect(() => {
        getAllCashRecords();
    }, [getAllCashRecords]);

    const deleteRecord = async () => {
        setOpen(false);
        try {
            await ambulanciaApi.delete("/user/cashRecord/" + deletedRecord._id);
            getAllCashRecords();
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie")
            }
            return
        }
        enqueueSnackbar('Záznam bol úspešne odstránený z pokladnice !', { variant: 'success' });
    }

    return (
        <>
            <Container maxWidth="xl">
                <h1 className='centerToMiddle'>Pokladnica</h1>
                <br></br>
                <Box sx={{ display: 'flex', height: 550, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        getRowId={(rows) => rows._id}
                        //checkboxSelection
                        disableSelectionOnClick
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    Pokladnica je prázdna.
                                </Stack>
                            ),
                            NoResultsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    Zadanému filtru nevyhovuje žiadny záznam
                                </Stack>
                            )
                        }}
                        experimentalFeatures={{ newEditingApi: true }}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                    />
                </Box>
            </Container>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    Zmazať položku o hodnote {deletedRecord.totalPrice} € ?
                </DialogTitle>
                <DialogContent>
                    Skutočne chcete zmazať túto položku?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Zrušiť
                    </Button>
                    <Button
                        onClick={deleteRecord}
                        color="error"
                        startIcon={<DeleteIcon />}
                    >
                        Zmazať
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CashRegister;