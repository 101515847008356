import * as React from 'react';
import { useContext, useEffect } from "react";
import { UserContext } from '../../middleware/userContext';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ambulanciaApi from "../../api/ambulanciaApi";
import 'react-phone-input-2/lib/material.css'
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from "react-router-dom";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { green } from '@mui/material/colors';
import { styles } from "../styles";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import PhoneInput from 'react-phone-input-2';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import MenuItem from '@mui/material/MenuItem';



function EditPatient() {

    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { logoutUserContext } = useContext(UserContext);
    const { state } = useLocation();
    const { patientData } = state || {};

    //otvorenie modal okna po uspesnej registracii
    const [open, setOpen] = React.useState(false);

    //atribúty pre validáciu formulára priamo počas písania
    const [emailText, setEmailText] = React.useState(patientData.email);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
    const [telephone, setTelephone] = React.useState(patientData.phone);

    const [doctor, setDoctor] = React.useState(patientData.assignedDoctor);
    const [doctors, setDoctors] = React.useState([]);
    const [sex, setSex] = React.useState(patientData.sex);


    //regexy pre validaciu na klientovi
    const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    /*Validácia emailu */
    useEffect(() => {
        if (!regexEmail.test(emailText)) {
            setEmailErrorMessage(
                "Neplatný email"
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailText]);

    /*Validácia emailu */
    useEffect(() => {
        if (emailText.length < 1 || regexEmail.test(emailText)) {
            setEmailErrorMessage("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailText, emailErrorMessage]);

    const getAllDoctors = async () => {
        try {
            const apiResponseAllDoctors = await ambulanciaApi.get("/user/getAllDoctors");
            setDoctors(apiResponseAllDoctors.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }

    useEffect(() => {
        getAllDoctors();
        return () => {
            setDoctors([]);
        };
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    function formatPhoneNumber(number) {
        let formattedNumber = number;
        if (!formattedNumber.startsWith('+')) {
            formattedNumber = formattedNumber.replace(/\s/g, ''); // odstránime všetky medzery
            formattedNumber = formattedNumber.replace(/(\d{3})/g, '$1 '); // vložíme medzery za každý tretí znak
            formattedNumber = "+" + formattedNumber;
        }
        return formattedNumber;
    }

    /* Vytvorenie pacienta s validáciou zo servera*/
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let email = data.get("email");
        let name = data.get("name");
        let surname = data.get("surname");
        let houseNumber = data.get("house_number");
        let city = data.get("city");
        let street = data.get("street");
        let psc = data.get("postal_code")
        let country = data.get("country");
        let phone = formatPhoneNumber(telephone);
        let academicDegree = data.get("academic_degree");
        let note = data.get("note");
        // let idNumber = data.get("id_number");
        let idEuNumber = data.get("id_EU_number");
        // let birthday = data.get("birthday");
        let assignedDoctor = data.get("assigned_doctor");
        let sex = data.get("sex");

        //regex for empty or null 
        let regex = /^$|^\s*$/;
        //let telRegex = /^[+]{1}(?:[0-9\-\(\)\/\.]\s?){6, 15}[0-9]{1}$/;

        if (regex.test(email) || regex.test(name) || regex.test(surname) || regex.test(houseNumber) || regex.test(city) || regex.test(street)
            || regex.test(psc) || regex.test(assignedDoctor) || regex.test(country) || regex.test(idEuNumber)
            || regex.test(phone) || regex.test(sex)) {
            enqueueSnackbar('Vyplňte všetky povinné polia!', { variant: 'warning' });
        } else {

            // console.log(assignedDoctor, name, surname, houseNumber, city, street, psc, country, email, phone, birthday, academicDegree, note, idNumber, idEuNumber);

            try {
                const apiResponse = await ambulanciaApi.put("/user/editPatient/" + patientData._id,
                    {
                        name, surname, houseNumber, city, street, psc, country, email, phone, academicDegree, note, idEuNumber, assignedDoctor, sex
                    },
                );
                if (apiResponse.status === 200) {
                    setOpen(true);
                    enqueueSnackbar('Pacient bol úspešne aktualizovaný!', { variant: 'success' });
                    navigate("/pacient", {
                        state: {
                            userId: patientData._id
                        }
                    })
                }
            }

            catch (error) {
                if (error.response.status === 400) {
                    // console.error("Api ERROR: ", error.response.data);
                    if (error.response.data.message === "validation.idNumber_already_exist")
                        enqueueSnackbar('Tento pacient už existuje!', { variant: 'error' });
                    else
                        enqueueSnackbar('Vyskytla sa chyba!', { variant: 'error' });
                }
                else {
                    enqueueSnackbar('Vyskytla sa chyba!', { variant: 'error' });
                }
            }
        }
    }

    return (
        <Container component="main" maxWidth="xl" >
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <PersonAddAlt1Icon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Úprava pacienta
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }} justifyContent="center">


                    <br></br>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    required
                                    name='assigned_doctor'
                                    value={doctor}
                                    fullWidth
                                    onChange={(e) => setDoctor(e.target.value)}
                                    select // tell TextField to render select
                                    label="Priradenie k doktorovi"
                                >
                                    {
                                        doctors.map((doctor) => (
                                            <MenuItem key={doctor.email} value={doctor.name + " " + doctor.surname + " (" + doctor.email + ") "}>{doctor.name + " " + doctor.surname + " (" + doctor.email + ") "}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider>
                                    <Chip label="Údaje o pacientovi" />
                                </Divider>
                            </Grid>
                            <Grid item xs={12} sm={1}>
                                <TextField
                                    required
                                    name='sex'
                                    value={sex}
                                    fullWidth
                                    onChange={(e) => setSex(e.target.value)}
                                    select // tell TextField to render select
                                    label="Pohlavie"
                                >
                                    <MenuItem value={"Muž"}>Muž</MenuItem>
                                    <MenuItem value={"Žena"}>Žena</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={1}>
                                <TextField
                                    name="academic_degree"
                                    type="academic_degree"
                                    fullWidth
                                    id="academic_degree"
                                    label="Titul"
                                    defaultValue={patientData.academicDegree}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    name="name"
                                    type="name"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Meno"
                                    autoFocus
                                    defaultValue={patientData.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    required
                                    fullWidth
                                    type="surname"
                                    id="surname"
                                    label="Priezvisko"
                                    name="surname"
                                    defaultValue={patientData.surname}
                                />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <TextField
                                    disabled
                                    required
                                    name='birthday'
                                    id="birthday"
                                    label="Dátum narodenia"
                                    type="date"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    defaultValue={patientData.birthday.substring(0, 10)}
                                />
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <TextField
                                    disabled
                                    required
                                    fullWidth
                                    type="id_number"
                                    id="id_number"
                                    label="Rodné číslo"
                                    name="id_number"
                                    defaultValue={patientData.idNumber}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    required
                                    fullWidth
                                    type="id_EU_number"
                                    id="id_EU_number"
                                    label="Identifikačné číslo EU preukazu "
                                    name="id_EU_number"
                                    defaultValue={patientData.idEuNumber}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    id="note"
                                    name="note"
                                    label="Poznámka k pacientovi"
                                    rows={4}
                                    multiline
                                    defaultValue={patientData.note}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Divider>
                                    <Chip label="Kontaktné informácie" />
                                </Divider>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <TextField
                                    required
                                    fullWidth
                                    name="country"
                                    id="country"
                                    label="Krajina"
                                    defaultValue={patientData.country}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField
                                    required
                                    name="city"
                                    type="city"
                                    fullWidth
                                    id="city"
                                    label="Mesto"
                                    defaultValue={patientData.city}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    required
                                    name="postal_code"
                                    type="postal_code"
                                    fullWidth
                                    id="postal_code"
                                    label="PSČ"
                                    defaultValue={patientData.postalCode}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    required
                                    name="street"
                                    type="street"
                                    fullWidth
                                    id="street"
                                    label="Ulica"
                                    defaultValue={patientData.street}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    required
                                    name="house_number"
                                    type="house_number"
                                    fullWidth
                                    id="house_number"
                                    label="Popisné číslo"
                                    defaultValue={patientData.houseNumber}
                                />
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <TextField
                                    required
                                    fullWidth
                                    type="Email"
                                    error={!regexEmail.test(emailText) && emailText.length > 0}
                                    onChange={(e) => setEmailText(e.target.value)}
                                    helperText={emailErrorMessage}
                                    value={emailText}
                                    id="email"
                                    label="Email"
                                    name="email"
                                    autoComplete='off'
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} >
                                <PhoneInput
                                    //width = "400"
                                    fullWidth
                                    country="sk"
                                    specialLabel="Telefónne číslo*"
                                    regions={'europe'}
                                    autoComplete='off'
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: false
                                    }}
                                    onChange={(value, data) => {
                                        setTelephone(value, data);
                                    }}
                                    value={patientData.phone}

                                />
                            </Grid>

                        </Grid>
                    </LocalizationProvider >
                    <Box textAlign='center'>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Uložiť zmeny
                        </Button>
                    </Box>
                </Box>
            </Box>
            <div>
                <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={styles.BoxSuccess} >
                        <Typography id="modal-modal-title" variant="h5" component="h2" align="center">
                            Registárcia úspešná !
                        </Typography>
                        <Container align="center">
                            <Avatar sx={{ width: 100, height: 100, bgcolor: "white" }}  >
                                <CheckCircleOutlinedIcon sx={{ width: 100, height: 100, color: green[500] }} />
                            </Avatar>
                        </Container>
                    </Box>
                </Modal>
            </div>

        </Container>
    );
}

export default EditPatient;