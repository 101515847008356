import * as React from 'react';
import ambulanciaApi from "../../api/ambulanciaApi";
import { useState, useContext, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../middleware/userContext';
import { Typography, Paper, Container, Button, Box, Grid, Stack, IconButton, Divider, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function PriceList() {
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { logoutUserContext } = useContext(UserContext);
    const [tariffs, setTariffs] = useState([]);
    const [clickedTariff, setClickedTariff] = React.useState([]);
    const [openCreateTariff, setOpenCreateTariff] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);

    function handleOpenDelete(e) {
        setOpenDelete(true);
        setClickedTariff(e);
    }

    function handleOpenEdit(e) {
        setOpenEdit(true);
        setClickedTariff(e);
    }

    const deleteTariff = async () => {
        setOpenDelete(false);
        try {
            await ambulanciaApi.delete("/user/tariff/" + clickedTariff._id);
            getAllTariffs();
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie")

            }
            return
        }
        enqueueSnackbar('Položka bola úspešne odstránená z cenníka !', { variant: 'success' });
    }


    const getAllTariffs = React.useCallback(async () => {
        try {
            const apiResponseAllTariffs = await ambulanciaApi.get("/user/getAllTariffs");
            let temp = apiResponseAllTariffs.data;
            temp.sort((a, b) => a.code - b.code);
            setTariffs(temp);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }, [logoutUserContext, navigate]);

    useEffect(() => {
        getAllTariffs();
    }, [getAllTariffs]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let name = data.get("name");
        let code = data.get("code");
        let price = data.get("price");

        //regex for empty or null 
        let regex = /^$|^\s*$/;

        if (regex.test(name) || (regex.test(code)) || (regex.test(price))) {
            enqueueSnackbar('Vyplňte všetky povinné polia!', { variant: 'warning' });
        } else {
            try {
                const apiResponse = await ambulanciaApi.post("/user/createTariff",
                    {
                        name, code, price
                    },
                );
                if (apiResponse.status === 201) {
                    setOpenCreateTariff(false);
                    enqueueSnackbar('Položka bola úspešne pridaná do cenníku !', { variant: 'success' });
                }
            }
            catch (error) {
                if (error.response.data.message === "validation.code_already_exist")
                    enqueueSnackbar('Tento kód už existuje!', { variant: 'error' });
                else
                    enqueueSnackbar('Vyskytla sa chyba!', { variant: 'error' });
            }
        }
        getAllTariffs();
    }

    const handleSubmitEdit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let name = data.get("name");
        let code = data.get("code");
        let price = data.get("price");

        //regex for empty or null 
        let regex = /^$|^\s*$/;

        if (regex.test(name) || (regex.test(code)) || (regex.test(price))) {
            enqueueSnackbar('Vyplňte všetky povinné polia!', { variant: 'warning' });
        } else {
            try {
                const apiResponse = await ambulanciaApi.put("/user/tariff/" + clickedTariff._id,
                    {
                        name, code, price
                    },
                );
                if (apiResponse.status === 200) {
                    setOpenEdit(false);
                    enqueueSnackbar('Položka bola úspešne aktualizovaná !', { variant: 'success' });
                }
            }
            catch (error) {
                if (error.response.data.message === "validation.code_already_exist")
                    enqueueSnackbar('Tento kód už existuje!', { variant: 'error' });
                else
                    enqueueSnackbar('Vyskytla sa chyba!', { variant: 'error' });
            }
        }
        getAllTariffs();
    }

    return (
        <>
            <Container maxWidth="xl">
                <br></br>

                <Paper
                    elevation={8}
                    sx={{
                        display: 'flex',
                        maxHeight: 700,
                        overflow: 'auto',
                    }}
                >
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} sm={2}>
                            <Typography className='centerToMiddle' variant="h6" gutterBottom>
                                Kód výkonu
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography className='centerToMiddle' variant="h6" gutterBottom>
                                Názov výkonu
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Typography variant="h6" gutterBottom>
                                Cena
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Typography variant="h6" gutterBottom>
                                Akcia
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
                <Box sx={{
                    display: 'flex',
                    justifyContent: "center"
                }}>
                    <IconButton onClick={() => setOpenCreateTariff(true)} aria-label="delete" size="large">
                        <AddCircleOutlineIcon fontSize="large" />
                    </IconButton>
                </Box>

                <Paper
                    elevation={8}
                    sx={{
                        display: 'flex',
                        maxHeight: 600,
                        overflow: 'auto',
                        P: 1
                    }}
                >
                    <Grid container spacing={2} justifyContent="center">
                        {tariffs.length < 1 ? <Grid item xs={12} sm={8}>
                            <Typography className='centerToMiddle' variant="h6" gutterBottom>
                                Cenník je prázdny, pridajte prvú položku stlačením tlačidla  <AddCircleOutlineIcon fontSize="large" />
                            </Typography>
                        </Grid> :
                            <>
                                <Grid item xs={12}>
                                    <Divider></Divider>
                                </Grid>
                                {tariffs.map((tariff) => (
                                    <Grid key={tariff._id} item xs={12}>
                                        <Box key={tariff._id} sx={{ display: 'flex' }}>
                                            <Grid item xs={12} sm={2}>
                                                <Typography className='centerToMiddle' variant="h6" gutterBottom>
                                                    {tariff.code}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <Typography className='centerToMiddle' variant="h6" gutterBottom>
                                                    {tariff.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={1}>
                                                <Typography className='centerToMiddle' variant="h6" gutterBottom>
                                                    {tariff.price} €
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={1}>
                                                <Stack direction="row" alignItems="center" spacing={1} >
                                                    <IconButton aria-label="delete" size="large" onClick={() => handleOpenEdit(tariff)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="delete" size="large" onClick={() => handleOpenDelete(tariff)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Stack>
                                            </Grid>
                                        </Box>
                                        <Grid item xs={12}>
                                            <Divider></Divider>
                                        </Grid>
                                    </Grid>
                                ))}
                            </>
                        }
                    </Grid>
                </Paper>
            </Container>

            <Dialog open={openCreateTariff} onClose={() => setOpenCreateTariff(false)}>
                <DialogTitle>Nová položka</DialogTitle>
                <Box component="form" onSubmit={handleSubmit} justifyContent="center">
                    <DialogContent>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} >
                                <TextField
                                    name="name"
                                    type="name"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Názov výkonu"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <TextField
                                    name="code"
                                    type="code"
                                    required
                                    fullWidth
                                    id="code"
                                    label="Kód výkonu"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="price"
                                    type="number"
                                    required
                                    fullWidth
                                    id="price"
                                    label="Cena"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenCreateTariff(false)}>Zrušiť</Button>
                        <Button type="submit" >Pridať</Button>
                    </DialogActions>
                </Box>
            </Dialog>

            <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
                <DialogTitle>
                    Zmazať položku "{clickedTariff.name}"?
                </DialogTitle>
                <DialogContent>
                    Skutočne chcete zmazať túto položku?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDelete(false)} color="primary">
                        Zrušiť
                    </Button>
                    <Button
                        onClick={deleteTariff}
                        color="error"
                        startIcon={<DeleteIcon />}
                    >
                        Zmazať
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
                <DialogTitle>Nová položka</DialogTitle>
                <Box component="form" onSubmit={handleSubmitEdit} justifyContent="center">
                    <DialogContent>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} >
                                <TextField
                                    name="name"
                                    type="name"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Názov výkonu"
                                    defaultValue={clickedTariff.name}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <TextField
                                    name="code"
                                    type="code"
                                    required
                                    fullWidth
                                    id="code"
                                    label="Kód výkonu"
                                    defaultValue={clickedTariff.code}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="price"
                                    type="number"
                                    required
                                    fullWidth
                                    id="price"
                                    label="Cena"
                                    defaultValue={clickedTariff.price}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenEdit(false)}>Zrušiť</Button>
                        <Button type="submit" >Aktualizovať</Button>
                    </DialogActions>
                </Box>
            </Dialog>

        </>
    );
}

export default PriceList;

