import React, { useState, useEffect } from 'react';
import ImageMapper from 'react-img-mapper';
import coordinates from "./coords.json"

function ImageMapperComponent(props) {

    let t = "tooth" + props.tooth;

    const [areas, setAreas] = useState([]);

    const setAreaSide = React.useCallback(async () => {
        if (props.orientation === "side") {
            setAreas([
                { id: t + "side", name: "side", shape: 'poly', coords: coordinates[t].side, preFillColor: props.toothObject.side.actualColor, active: false,lineWidth: 5 },
                { id: t + "radix", name: "radix", shape: 'poly', coords: coordinates[t].radix, preFillColor: props.toothObject.radix.actualColor, active: false},
            ]);
        } else {
            setAreas([
                { id: t + "top", name: "top", shape: 'poly', coords: coordinates[t].top, preFillColor: props.toothObject.top.actualColor, active: false },
                { id: t + "mid", name: "mid", shape: 'poly', coords: coordinates[t].mid, preFillColor: props.toothObject.mid.actualColor, active: false },
                { id: t + "left", name: "left", shape: 'poly', coords: coordinates[t].left, preFillColor: props.toothObject.left.actualColor, active: false },
                { id: t + "bottom", name: "bottom", shape: 'poly', coords: coordinates[t].bottom, preFillColor: props.toothObject.bottom.actualColor, active: false },
                { id: t + "right", name: "right", shape: 'poly', coords: coordinates[t].right, preFillColor: props.toothObject.right.actualColor, active: false },
            ]);
        }

    }, [props.orientation, props.toothObject.bottom.actualColor, props.toothObject.left.actualColor, props.toothObject.mid.actualColor, props.toothObject.radix.actualColor, props.toothObject.right.actualColor, props.toothObject.side.actualColor, props.toothObject.top.actualColor,t]);

    useEffect(() => {
        setAreaSide();
    }, [setAreaSide]);

    useEffect(() => {
        //console.log("effect");
        if (props.tooth === props.actualyClickedTooth) {
            //console.log("useeffect");
            setAreaSide();
        }
    }, [props.change,props.tooth,props.actualyClickedTooth,setAreaSide]);

    /*
    const handleAreaClick = (area) => {
        console.log(area);
        const newAreas = [...areas];
        const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
        console.log(area.name);
        //const clickedArea = newAreas.find((a) => a.name === props.name);
        // let clickedArea2 = newAreas.find((a) => a.id === t);
        //clickedArea.preFillColor = randomColor;
        //clickedArea2.preFillColor = randomColor;
        // newAreas[0].preFillColor = props.fillColor;
        setAreas(newAreas);
    }

    function handleClick() {
        console.log("klikol som na " + props.tooth);
        const newAreas = [...areas];
        // const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
        newAreas.forEach(area => {
            switch (area.name) {
                case "top":
                    area.preFillColor = props.toothObject.top;
                    break;
                case "bottom":
                    area.preFillColor = props.toothObject.bottom;
                    break;
                case "left":
                    area.preFillColor = props.toothObject.left;
                    break;
                case "right":
                    area.preFillColor = props.toothObject.right;
                    break;
                case "mid":
                    area.preFillColor = props.toothObject.mid;
                    break;
                case "side":
                    area.preFillColor = props.toothObject.side;
                    break;
                default:
                    break;
            }
        });

        //const clickedArea = newAreas.find((a) => a.name === props.toothObject);
        //clickedArea.preFillColor = randomColor;
        //newAreas[0].preFillColor = props.fillColor;
        setAreas(newAreas);
        console.log(props.toothObject);
        console.log("klikool som na zub č." + t);
        //console.log(clickedArea);
    }

    function handleChange() {
        console.log("change" + t);
    }

*/

    return (
        <div id={props.name} >
            <ImageMapper
                src={props.src}
                map={{
                    id: props.name + "image_map",
                    name: props.name + "image_map",
                    areas: areas
                    
                }}
                areaKeyName={props.name}
            //onClick={(area) => handleAreaClick(area)}
            //responsive={false}
            //parentWidth={1200}
            //fillColor="rgba(63, 42, 222, 0.27)"

            />
        </div>
    );
}

export default ImageMapperComponent;