import * as React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import ambulanciaApi from "../api/ambulanciaApi";
import { UserContext } from '../middleware/userContext';

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

function LoginPage() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { loginUserContext, logoutUserContext } = useContext(UserContext);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    let email = data.get("email");
    let password = data.get("password");

    try {
      const apiResponse = await ambulanciaApi.post("/public/signin", { email, password });
      if (apiResponse.status === 200) {
        localStorage.setItem('authToken', apiResponse.data.token);
        try {
          //VYTIAHNUTIE PRIHLASENÉHO USERA Z DB PODĽA MAILU
          const apiResponse2 = await ambulanciaApi.get("/user/current");
          if (apiResponse2.status === 200)
            loginUserContext();
        } catch (error) {
          if (error.response.status === 500) {
            // console.error("Api ERROR: ", error.response.data);
            logoutUserContext();
          }
        }
        navigate("/domov");
      }
    } catch (error) {
      //  console.log(error);
      if (error.response.status === 400) {
        //Chyba pri prihlásovaní
        enqueueSnackbar('Nesprávne prihlasovacie údaje!', { variant: 'warning' });
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs" >
      <CssBaseline />
      <Paper
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p:4,
          borderRadius:6
        }}
        elevation={4}
        align="center"
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h4">
          Prihlásenie
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Zadaj Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Zadaj Heslo"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            //fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Prihlásenie
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default LoginPage;
