import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../middleware/userContext';
import { useNavigate } from "react-router-dom";
import ambulanciaApi from "../../api/ambulanciaApi";

import { Typography, Container, Paper } from '@mui/material/';
//import moment from "moment";

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';


function MainPage() {
    const [time, setTime] = useState(new Date());
    const [todayEvents, setTodayEvents] = useState([]);
    const [user, setUser] = React.useState("");
    const [programEnd, setProgramEnd] = React.useState("");
    const { logoutUserContext } = useContext(UserContext);
    let navigate = useNavigate();


    const getUser = React.useCallback(async () => {
        try {
            const apiResponseUser = await ambulanciaApi.get("/user/current");
            if (apiResponseUser.status === 200)
                setUser(apiResponseUser.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }, [logoutUserContext, navigate]);

    useEffect(() => {
        getUser();
    }, [getUser]);

    const getDoctorEvents = React.useCallback(async () => {
        try {
            //console.log(x);
            // console.log(selectedDoctorCalendar);
            const apiResponseEvents = await ambulanciaApi.get("/user/getDoctorEvents/" + user.email);
            let temp = [];
            apiResponseEvents.data.sort((a, b) => new Date(a.start) - new Date(b.start));
            apiResponseEvents.data.forEach(element => {
                element.start = new Date(element.start);
                if (element.start.toDateString() === new Date().toDateString()) {
                    temp.push(element);
                }
                setProgramEnd(element.end);
            });
            setTodayEvents(temp);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }, [logoutUserContext, navigate, user.email]);

    useEffect(() => {
        getDoctorEvents();
    }, [getDoctorEvents]);

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => {
            clearInterval(timerID);
        };
    });

    function tick() {
        setTime(new Date());
    }

    function getTodayDate() {
        let date = new Date();
        return date.toLocaleDateString('sk-SK', { weekday: 'long' }) + ", " + date.getDate() + ". " + date.toLocaleDateString('sk-SK', { month: 'long' }) + "\n" + date.getFullYear();
    }

    return (
        <>
            <Container maxWidth="xl" align="center"> 
                <Paper variant="outlined" elevation={0} sx={{ mt: 3 }} align="center" >
                    <Typography variant="h4" sx={{ mt: 2 }}>
                        {getTodayDate().charAt(0).toUpperCase() + getTodayDate().slice(1)}
                    </Typography>
                    <Typography variant="h4" >
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} variant="h4" color="text.secondary">
                        {time.toLocaleTimeString()}
                    </Typography>
                </Paper >

                {todayEvents.length < 1 ? <></> :
                    <>
                        <Typography variant="h5" sx={{mt:2,mb:2}} >Naplánované udalosti na dnešný deň</Typography>
                        <Paper
                            sx={{
                            
                                //display: "flex",
                                //flexDirection: "column",
                                //alignItems: "center",
                                //p: 4,
                                pt:2,
                                borderRadius: 6
                            }}
                            elevation={4}
                            align="center"
                        >

                            {todayEvents.map((event) => (
                                <TimelineItem key={event._id}>
                                    <TimelineOppositeContent color="textSecondary">
                                        <Typography>{(new Date(event.start).getHours()) + ":" + ((new Date(event.start).getMinutes()) < 10 ? "0" + new Date(event.start).getMinutes() : new Date(event.start).getMinutes())}</Typography>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot variant="outlined" color="primary" />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent >
                                        <Typography variant="h6" component="h1">
                                            {event.title}
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                             <TimelineItem >
                                    <TimelineOppositeContent color="textSecondary">
                                        <Typography>{(new Date(programEnd).getHours()) + ":" + ((new Date(programEnd).getMinutes()) < 10 ? "0" + new Date(programEnd).getMinutes() : new Date(programEnd).getMinutes())}</Typography>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot variant="outlined" color="primary" />
                                       
                                    </TimelineSeparator>
                                    <TimelineContent >
                                        <Typography variant="h6" component="h1">
                                            Koniec programu
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                        </Paper>
                    </>
                }
            </Container>
        </>
    );
};

export default MainPage;