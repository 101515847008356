import * as React from 'react';
import ambulanciaApi from "../../api/ambulanciaApi";
import { useState, useContext, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../middleware/userContext';
import {
    Typography, Paper, Container, Button, Box, Grid, IconButton, Divider, TextField,
    MenuItem, Chip, OutlinedInput, Select, InputLabel, FormControl, Slide, Autocomplete
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css'

import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineContent, TimelineConnector } from '@mui/lab';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import ImageGallery from './imageGallery';

function HealthCard(props) {
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { logoutUserContext } = useContext(UserContext);
    const [records, setRecords] = useState([]);
    const [clickedRecord, setClickedRecord] = React.useState([]);
    const [openCreateRecord, setOpenCreateRecord] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [selectedDoctorInRecord, setSelectedDoctorInRecord] = React.useState('');
    const [doctors, setDoctors] = React.useState([]);
    const [selectedNursesInRecord, setSelectedNursesInRecord] = React.useState([]);
    const [nurses, setNurses] = React.useState([]);
    const [title, setTitle] = React.useState([]);
    const [text, setText] = useState('');
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [allTariffs, setAllTariffs] = useState([]);
    const [selectedTariffs, setSelectedTariffs] = useState([]);
    const [cashRecords, setCashRecords] = useState([]);
    const [patient, setPatient] = useState([]);
    const [user, setUser] = React.useState("");
    const [isDisabled, setIsDisabled] = React.useState(false);


    /* Získanie prihláseného usera */
    const getUser = React.useCallback(async () => {
        try {
            const apiResponseUser = await ambulanciaApi.get("/user/current");
            if (apiResponseUser.status === 200)
                setUser(apiResponseUser.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                console.error("TOKEN ERROR: ", error.response.data);
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }, [logoutUserContext, navigate]);

    useEffect(() => {
        getUser();
    }, [getUser]);

    const pull_data = (data) => {
        setGalleryOpen(data);
    }

    const getPatient = React.useCallback(async () => {
        try {
            const apiResponse = await ambulanciaApi.get("/user/getPatientById/" + props.userId);
            setPatient(apiResponse.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }, [logoutUserContext, navigate,props.userId]);

    useEffect(() => {
        getPatient();
    }, [getPatient]);

    const getAllCashRecords = React.useCallback(async () => {
        try {
            const apiResponseCashRecords = await ambulanciaApi.get("/user/getAllCashRecords");
            setCashRecords(apiResponseCashRecords.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }, [logoutUserContext, navigate]);

    useEffect(() => {
        getAllCashRecords();
    }, [getAllCashRecords]);

    const getAllTariffs = React.useCallback(async () => {
        try {
            const apiResponseAllTariffs = await ambulanciaApi.get("/user/getAllTariffs");
            let temp = apiResponseAllTariffs.data;
            temp.sort((a, b) => a.code - b.code);
            setAllTariffs(temp);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }, [logoutUserContext, navigate]);

    useEffect(() => {
        getAllTariffs();
    }, [getAllTariffs]);

    const getAllDoctors = React.useCallback(async () => {
        try {
            const apiResponseAllDoctors = await ambulanciaApi.get("/user/getAllDoctors");
            setDoctors(apiResponseAllDoctors.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }, [logoutUserContext, navigate]);

    useEffect(() => {
        getAllDoctors();
    }, [getAllDoctors]);

    const getAllNurses = React.useCallback(async () => {
        try {
            const apiResponseAllNurses = await ambulanciaApi.get("/user/getAllNurses");
            setNurses(apiResponseAllNurses.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }, [logoutUserContext, navigate]);

    useEffect(() => {
        getAllNurses();
    }, [getAllNurses]);

    const getHealthRecords = React.useCallback(async () => {
        try {
            const apiResponseRecords = await ambulanciaApi.get("/user/getHealthRecords/" + props.userId);
            let tempRecords = apiResponseRecords.data;
            tempRecords.sort((a, b) => new Date(b.date) - new Date(a.date));
            setRecords(tempRecords);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }, [logoutUserContext, navigate, props.userId]);

    useEffect(() => {
        getHealthRecords();
    }, [getHealthRecords]);

    const handleSubmitEdit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let doctor = selectedDoctorInRecord;
        let title = data.get("title");
        let nurses = selectedNursesInRecord;
        let tariffs = selectedTariffs;
        let idNumber = props.userId;
        let totalPrice = 0;
        selectedTariffs.forEach(element => {
            totalPrice += element.price;
        });
        let payed = false;
        //regex for empty or null 
        let regex = /^$|^\s*$/;

        if (regex.test(doctor) || (regex.test(title)) || (regex.test(text))) {
            enqueueSnackbar('Vyplňte všetky povinné polia!', { variant: 'warning' });
        } else {
            try {
                // console.log(doctor, title, nurses, text);
                const apiResponse = await ambulanciaApi.put("/user/healthRecord/" + clickedRecord._id,
                    {
                        doctor, title, nurses, text, tariffs, idNumber, totalPrice, user, payed, patient
                    },
                );
                if (apiResponse.status === 200) {
                    setOpenEdit(false);
                    enqueueSnackbar('Záznam bol úspešne aktualizovaný !', { variant: 'success' });
                }
            }
            catch (error) {
                enqueueSnackbar('Vyskytla sa chyba!', { variant: 'error' });
            }
        }
        getHealthRecords();
        getAllCashRecords();
        setText("");
        setSelectedDoctorInRecord("");
        setSelectedNursesInRecord([]);
        setTitle("");
        setOpenEdit(false);
        setIsDisabled(false);
    }

    const deleteRecord = async () => {
        setOpenDelete(false);
        try {
            await ambulanciaApi.delete("/user/healthRecord/" + clickedRecord._id);
            getHealthRecords();
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie")

            }
            return
        }
        enqueueSnackbar('Záznam bol úspešne odstránený zo zubnej karty !', { variant: 'success' });
    }

    function handleOpenDelete(tempRecord) {
        setOpenDelete(true);
        setClickedRecord(tempRecord);
    }

    function handleCloseEdit() {
        setText("");
        setSelectedDoctorInRecord("");
        setSelectedNursesInRecord([]);
        setTitle("");
        setSelectedTariffs([]);
        setOpenEdit(false);
        setIsDisabled(false);
    }

    function handleOpenEdit(tempRecord) {
        setOpenEdit(true);
        setText(tempRecord.text);
        setSelectedDoctorInRecord(tempRecord.doctor);
        setSelectedNursesInRecord(tempRecord.nurses);
        setTitle(tempRecord.title);
        setClickedRecord(tempRecord);
        getTariff(tempRecord._id);
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    const TOOLBAR_OPTIONS = [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike", "blockquote", "link"],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ['link', 'image', 'video'],
        ["clean"]
    ];

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedNursesInRecord(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let idNumber = props.userId;
        let doctor = selectedDoctorInRecord;
        let title = data.get("title");
        let nurses = selectedNursesInRecord;
        let tariffs = selectedTariffs;
        let totalPrice = 0;
        selectedTariffs.forEach(element => {
            totalPrice += element.price;
        });
        let payed = false;
        //regex for empty or null 
        let regex = /^$|^\s*$/;

        if (regex.test(doctor) || (regex.test(title)) || (regex.test(text))) {
            enqueueSnackbar('Vyplňte všetky povinné polia!', { variant: 'warning' });
        } else {
            //console.log(idNumber ,doctor,title,nurses,text);            
            try {
                const apiResponse = await ambulanciaApi.post("/user/createHealthRecord",
                    {
                        idNumber, doctor, title, nurses, text, tariffs, totalPrice, patient, user, payed
                    },
                );
                if (apiResponse.status === 201) {
                    setOpenCreateRecord(false);
                    enqueueSnackbar('Záznam bol pridaný do zubnej karty !', { variant: 'success' });
                    setSelectedDoctorInRecord("#2196f3");
                    setSelectedNursesInRecord([]);
                    setText('');
                    setTitle("");
                    setSelectedTariffs([]);
                }
            }
            catch (error) {
                enqueueSnackbar('Vyskytla sa chyba, záznam sa nepodarilo vytvoriť !', { variant: 'error' });
            }
        }
        getHealthRecords();
        getAllCashRecords();
    }

    function extractTarrifs(id) {
        let temp = cashRecords.find(element => element.healthRecordId === id);
        let outputString = "";
        if (temp !== undefined) {
            let tempTariffs = temp.tariffs;
            tempTariffs.forEach(element => {
                outputString += element.code + " - " + element.name + ", ";
            });
        }
        return outputString;
    }

    function getTariff(id) {
        let temp = cashRecords.find(element => element.healthRecordId === id);
        let tempTariffs = [];
        if (temp !== undefined) {
            tempTariffs = temp.tariffs;
            setIsDisabled(temp.payed);
        }
        setSelectedTariffs(tempTariffs);
    }
   // const [selectedOptions, setSelectedOptions] = useState([]);

    /*
    const handleOptionChange = (event, values) => {
        setSelectedOptions(values);
    };

    const handleOptionClick = (option) => {
        setSelectedOptions([...selectedOptions, option]);
    };
*/
    return (
        <>
            {!galleryOpen ?
                <Slide direction="right" in={true} mountOnEnter unmountOnExit>
                    <Container maxWidth="xl">
                        <br></br>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: "center"
                        }}>
                            <IconButton onClick={() => setOpenCreateRecord(true)} aria-label="delete" size="large">
                                <AddCircleOutlineIcon fontSize="large" />
                            </IconButton>
                            <IconButton onClick={() => setGalleryOpen(true)} aria-label="delete" size="large">
                                <PhotoLibraryIcon fontSize="large" />
                            </IconButton>
                        </Box>

                        <Timeline
                            sx={{
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                    flex: 0.1,
                                },
                            }}
                        >
                            {records.length < 1 ?
                                <Paper
                                    elevation={8}
                                    sx={{
                                        m: 1,
                                        P: 1
                                    }}
                                >
                                    <Typography className='centerToMiddle' variant="h6" gutterBottom>
                                        Zubná karta je prázdna
                                    </Typography>
                                </Paper>

                                :
                                <Container maxWidth="xl">
                                    {records.map((record) => (
                                        <TimelineItem key={record._id}>
                                            <TimelineOppositeContent color="textSecondary">
                                                <Typography variant="h6" component="span">
                                                    {new Date(record.date).getDate() + ". " + new Date(record.date).toLocaleDateString('sk-SK', { month: 'long' }) + "\n" + new Date(record.date).getFullYear()}
                                                </Typography>
                                                <Typography>{(new Date(record.date).getHours()) + ":" + ((new Date(record.date).getMinutes()) < 10 ? "0" + new Date(record.date).getMinutes() : new Date(record.date).getMinutes())}</Typography>

                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot color="primary">
                                                    < LocalHospitalIcon />
                                                </TimelineDot>
                                                <IconButton color="error" aria-label="delete" size="large" onClick={() => handleOpenDelete(record)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                <IconButton color="color.secondary" aria-label="edit" size="large" onClick={() => handleOpenEdit(record)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent >
                                                <Paper elevation={3} style={{ padding: '1rem' }}>
                                                    <Typography variant="h6" component="h1">
                                                        {record.title}
                                                    </Typography>
                                                    <ReactQuill
                                                        value={record.text}
                                                        readOnly={true}
                                                        theme={"bubble"}
                                                    />
                                                    <Divider></Divider>
                                                    <Typography display="inline" style={{ fontWeight: 600 }} variant="subtitle2" gutterBottom>
                                                        Výkony :
                                                    </Typography>
                                                    <Typography display="inline" variant="subtitle2" gutterBottom>
                                                        {" " + extractTarrifs(record._id)}
                                                    </Typography>
                                                    <br></br>
                                                    <Typography display="inline" style={{ fontWeight: 600 }} variant="subtitle2" gutterBottom>
                                                        Zdravotné sestry :
                                                    </Typography>
                                                    <Typography display="inline" variant="subtitle2" gutterBottom>
                                                        {" " + record.nurses}
                                                    </Typography>
                                                    <br></br>
                                                    <Typography display="inline" style={{ fontWeight: 600 }} variant="subtitle2" gutterBottom>
                                                        Lekár:
                                                    </Typography>
                                                    <Typography display="inline" variant="subtitle2" gutterBottom>
                                                        {" " + record.doctor}
                                                    </Typography>
                                                </Paper>
                                            </TimelineContent>
                                        </TimelineItem>
                                    ))}
                                </Container>
                            }
                        </Timeline>


                        <Dialog fullWidth maxWidth="md" open={openCreateRecord} onClose={() => setOpenCreateRecord(false)}>
                            <DialogTitle>Nový záznam</DialogTitle>
                            <Box component="form" onSubmit={handleSubmit} justifyContent="center">
                                <DialogContent>
                                    <Grid container spacing={2} justifyContent="center">
                                        <Grid item xs={12} >
                                            <ReactQuill
                                                //className="ql-editor"
                                                theme="snow"
                                                placeholder="Začnite písať..."
                                                value={text}
                                                onChange={setText}
                                                modules={{
                                                    toolbar: {
                                                        container: TOOLBAR_OPTIONS
                                                    }
                                                }}
                                            //formats={formats}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField
                                                required
                                                id="title"
                                                name='title'
                                                value={title}
                                                fullWidth
                                                onChange={(e) => setTitle(e.target.value)}
                                                label="Názov záznamu"
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField
                                                required
                                                id="selected_doctor"
                                                name='selected_doctor'
                                                value={selectedDoctorInRecord}
                                                fullWidth
                                                onChange={(e) => setSelectedDoctorInRecord(e.target.value)}
                                                select
                                                label="Doktor"
                                                defaultValue=""
                                            >
                                                {
                                                    doctors.map((doctor) => (
                                                        <MenuItem key={doctor.email} value={doctor.name + " " + doctor.surname + " (" + doctor.email + ") "}>{doctor.name + " " + doctor.surname + " (" + doctor.email + ") "}</MenuItem>
                                                    ))
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl sx={{ display: "flex" }}>
                                                <InputLabel id="selected_nurses">Zdravotné sestričky</InputLabel>
                                                <Select
                                                    required
                                                    multiple
                                                    labelId="selected_nurses"
                                                    id="selected_nurses"
                                                    name='selected_nurses'
                                                    value={selectedNursesInRecord}
                                                    fullWidth
                                                    onChange={handleChange}
                                                    input={<OutlinedInput id="select-multiple-chip" label="Zdravotné sestričky" />}
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                            {selected.map((value) => (
                                                                <Chip key={value} label={value} />
                                                            ))}
                                                        </Box>
                                                    )}
                                                    label="Zdravotné sestričky"
                                                    MenuProps={MenuProps}
                                                    defaultValue=""

                                                >
                                                    {
                                                        nurses.map((nurse) => (
                                                            <MenuItem key={nurse.email} value={nurse.name + " " + nurse.surname + " (" + nurse.email + ") "}>{nurse.name + " " + nurse.surname + " (" + nurse.email + ") "}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl sx={{ display: "flex" }}>
                                                <Autocomplete
                                                    multiple
                                                    isOptionEqualToValue={(option, value) => option.code === value.code}
                                                    id="selected_codes"
                                                    options={allTariffs}
                                                    getOptionLabel={(option) => option.code + " - " + option.name}
                                                    filterSelectedOptions
                                                    value={selectedTariffs}
                                                    onChange={(event, value) => setSelectedTariffs(value)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Výkony"
                                                            placeholder=""
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setOpenCreateRecord(false)}>Zrušiť</Button>
                                    <Button type="submit" >Vytvoriť</Button>
                                </DialogActions>
                            </Box>
                        </Dialog>

                        <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
                            <DialogTitle>
                                Zmazať položku "{clickedRecord.title}"?
                            </DialogTitle>
                            <DialogContent>
                                Skutočne chcete zmazať túto položku?
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenDelete(false)} color="primary">
                                    Zrušiť
                                </Button>
                                <Button
                                    onClick={deleteRecord}
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                >
                                    Zmazať
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={openEdit} maxWidth="md" onClose={handleCloseEdit}>
                            <DialogTitle>Editovanie záznamu</DialogTitle>
                            <Box component="form" onSubmit={handleSubmitEdit} justifyContent="center">
                                <DialogContent>
                                    <Grid container spacing={2} justifyContent="center">
                                        <Grid item xs={12} >
                                            <ReactQuill
                                                //className="ql-editor"
                                                theme="snow"
                                                placeholder="Začnite písať..."
                                                value={text}
                                                onChange={setText}
                                                modules={{
                                                    toolbar: {
                                                        container: TOOLBAR_OPTIONS
                                                    }
                                                }}
                                            //formats={formats}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField
                                                required
                                                id="title"
                                                name='title'
                                                value={title}
                                                fullWidth
                                                onChange={(e) => setTitle(e.target.value)}
                                                label="Názov záznamu"
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField
                                                required
                                                id="selected_doctor"
                                                name='selected_doctor'
                                                value={selectedDoctorInRecord}
                                                fullWidth
                                                onChange={(e) => setSelectedDoctorInRecord(e.target.value)}
                                                select
                                                label="Doktor"
                                            >
                                                {
                                                    doctors.map((doctor) => (
                                                        <MenuItem key={doctor.email} value={doctor.name + " " + doctor.surname + " (" + doctor.email + ") "}>{doctor.name + " " + doctor.surname + " (" + doctor.email + ") "}</MenuItem>
                                                    ))
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl sx={{ display: "flex" }}>
                                                <InputLabel id="selected_nurses">Zdravotné sestričky</InputLabel>
                                                <Select
                                                    required
                                                    multiple
                                                    labelId="selected_nurses"
                                                    id="selected_nurses"
                                                    name='selected_nurses'
                                                    value={selectedNursesInRecord}
                                                    fullWidth
                                                    onChange={handleChange}
                                                    input={<OutlinedInput id="select-multiple-chip" label="Zdravotné sestričky" />}
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                            {selected.map((value) => (
                                                                <Chip key={value} label={value} />
                                                            ))}
                                                        </Box>
                                                    )}
                                                    label="Zdravotné sestričky"
                                                    MenuProps={MenuProps}
                                                >
                                                    {
                                                        nurses.map((nurse) => (
                                                            <MenuItem key={nurse.email} value={nurse.name + " " + nurse.surname + " (" + nurse.email + ") "}>{nurse.name + " " + nurse.surname + " (" + nurse.email + ") "}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl sx={{ display: "flex" }}>
                                                <Autocomplete
                                                    disabled={isDisabled}
                                                    multiple
                                                    isOptionEqualToValue={(option, value) => option.code === value.code}
                                                    id="selected_codes"
                                                    options={allTariffs}
                                                    getOptionLabel={(option) => option.code + " - " + option.name}
                                                    // filterSelectedOptions
                                                    value={selectedTariffs}
                                                    onChange={(event, value) => setSelectedTariffs(value)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Výkony"
                                                            placeholder=""
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseEdit}>Zrušiť</Button>
                                    <Button type="submit" >Uložiť zmeny </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                    </Container>
                </Slide> :
                <ImageGallery state={galleryOpen} func={pull_data} userId={props.userId} />}
        </>
    );
}

export default HealthCard;

