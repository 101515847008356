import * as React from 'react';
import { Container, Grid, Button, TextField, Paper } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import ambulanciaApi from "../../api/ambulanciaApi";
import { useContext, useEffect } from "react";
import { UserContext } from '../../middleware/userContext';




function ChangePassword() {
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { logoutUserContext } = useContext(UserContext);

    const [user, setUser] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = React.useState("");
    const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/;

    const getUser = React.useCallback(async () => {
        try {
            const apiResponseUser = await ambulanciaApi.get("/user/current");
            if (apiResponseUser.status === 200)
                setUser(apiResponseUser.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }, [logoutUserContext, navigate]);

    useEffect(() => {
        getUser();
    }, [getUser]);

    /*Validácia hesla */
    React.useEffect(() => {
        if (!regexPassword.test(password)) {
            setPasswordErrorMessage(
                "Heslo musí obsahovať aspoň 8 znakov, aspoň jednu číslicu , aspoň jeden malý a aspoň jeden veľký znak"
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password]);

    /*Validácia hesla */
    React.useEffect(() => {
        if (regexPassword.test(password)) {
            setPasswordErrorMessage("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password, passwordErrorMessage]);

    /*Validácia potvrdenia hesla */
    React.useEffect(() => {
        if (!(password === confirmPassword)) {
            setConfirmPasswordErrorMessage(
                "Heslá sa nezhodujú"
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmPassword]);

    /*Validácia potvrdenia hesla */
    React.useEffect(() => {
        if (password === confirmPassword) {
            setConfirmPasswordErrorMessage("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmPassword, confirmPasswordErrorMessage]);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let password = data.get("password");
        let password_repeat = data.get("confirmPassword");
        let _id = user._id;
        if (password === confirmPassword) {
            if (regexPassword.test(password)) {
                try {
                    const apiResponse = await ambulanciaApi.post("/user/change-password",
                        {
                            password,
                            password_repeat,
                            _id
                        },
                    );
                    if (apiResponse.status === 200) {
                        enqueueSnackbar('Heslo bolo úspešne zmenené!', { variant: 'success' });
                    }
                }

                catch (error) {
                    enqueueSnackbar('Vyskytla sa chyba!', { variant: 'error' });
                }
            }else {
                enqueueSnackbar('Heslo nebolo zmenené lebo nespĺňa požiadavky!', { variant: 'warning' });
            }
        } else {
            console.error("Zadané heslá sa nezhodujú!");
            enqueueSnackbar('Zadané heslá sa nezhodujú!', { variant: 'warning' });
        }
        setPassword("");
        setConfirmPassword("");
    }

    return (
        <Container maxWidth="xl" align="center">
            <Paper fullWidth variant="outlined" elevation={0} component="form" onSubmit={handleSubmit} sx={{ mt: 3, mb: 3 }} >
                <Grid container spacing={2} sx={{ mt: 2 }} align="center" justifyContent="center">
                    <Grid item xs={10} sm={5}>
                        <TextField
                            name="password"
                            type="password"
                            required
                            fullWidth
                            error={!regexPassword.test(password) && password.length > 0}
                            onChange={(e) => setPassword(e.target.value)}
                            helperText={password.length > 0 ? passwordErrorMessage : ""}
                            value={password}
                            id="password"
                            label="Nové heslo"
                            autoComplete='new-password'
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={10} sm={5}>
                        <TextField
                            required
                            fullWidth
                            type="password"
                            error={!(password === confirmPassword)}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            helperText={confirmPassword.length > 0 ? confirmPasswordErrorMessage : ""}
                            value={confirmPassword}
                            id="confirmPassword"
                            label="Potvrdenie hesla"
                            name="confirmPassword"
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"

                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Zmeniť heslo
                </Button>
            </Paper>
        </Container>
    );
};

export default ChangePassword;