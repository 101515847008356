import * as React from 'react';
import { useState, useContext, useEffect } from "react";
import { Container, IconButton, Stack, Box, TextField } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from "react-router-dom";
import ambulanciaApi from "../../api/ambulanciaApi";
import { UserContext } from '../../middleware/userContext';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from "dayjs";

function Patients() {
    let navigate = useNavigate();
    const { logoutUserContext } = useContext(UserContext);
    //const [rows, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [allPatients, setAllPatients] = React.useState([]);


    // let [columns, setColumns] = useState([]);

    const columns = [
        { field: 'name', headerName: 'Meno', flex: 1, align: "center", headerAlign: 'center' },
        { field: 'surname', headerName: 'Priezvisko', flex: 1, align: "center", headerAlign: 'center' },
        { field: 'sex', headerName: 'Pohlavie', maxWidth: 80, align: "center", headerAlign: 'center' },
        {
            field: 'birthday', headerName: 'Dátum narodenia', flex: 1, align: "center", headerAlign: 'center',
            valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
        },
        { field: 'phone', headerName: 'Telefónne číslo', flex: 1, align: "center", headerAlign: 'center' },
        { field: 'email', headerName: 'Email', flex: 2, align: "center", headerAlign: 'center' },
        { field: 'assignedDoctor', headerName: 'Priradený doktor', flex: 2, align: "center", headerAlign: 'center' }
    ];

    const getAllPatients = React.useCallback(async () => {
        try {
            const apiResponseAllUsers = await ambulanciaApi.get("/user/getAllPatients");
            setAllPatients(apiResponseAllUsers.data);
            setList(apiResponseAllUsers.data)
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }, [logoutUserContext, navigate]);

    useEffect(() => {
        getAllPatients();
    }, [getAllPatients]); //eslint-disable-line react-hooks/exhaustive-deps


    const [filter, setFilter] = useState('');
    const [list, setList] = useState([]);


    const handleFilterChange = (event) => {
        const newFilter = event.target.value;
        setFilter(newFilter);
        const filteredList = allPatients.filter(
            (item) =>
                item.name.toLowerCase().includes(newFilter.toLowerCase()) ||
                item.surname.toLowerCase().includes(newFilter.toLowerCase()));
        setList(filteredList);
    };

    return (
        <Container maxWidth="xl">
            <h1 className='centerToMiddle'>Pacienti</h1>
            <Box className='centerToMiddle' sx={{ mt: 1, mb: 2 }}>
                <TextField
                    id="SearchPatient"
                    name='SearchPatient'
                    label="Vyhľadať pacienta"
                    style={{ width: 300 }}
                    value={filter}
                    onChange={handleFilterChange}
                />
            </Box>
            <Box sx={{ display: 'flex', height: 550, width: '100%' }}>
                <DataGrid
                    rows={list}
                    columns={columns}
                    getRowId={(rows) => rows._id}
                    //checkboxSelection
                    disableSelectionOnClick
                    components={{
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                Databáza pacientov je prázdna. Začnite vytvorením nového pacienta.
                            </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                Zadanému filtru nevyhovuje žiadny záznam
                            </Stack>
                        )
                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    onCellClick={(e) => navigate("/pacient", {
                        state: {
                            userId: e.id
                        }
                    })}
                />
            </Box>
            <IconButton onClick={() => {
                navigate("/novy_pacient");
            }} aria-label="delete" size="large">
                <AddCircleOutlineIcon fontSize="large" />
            </IconButton>
        </Container>
    );
};

export default Patients;