import * as React from 'react';
import { useState, useContext, useEffect } from "react";
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from "react-router-dom";
import ambulanciaApi from "../../api/ambulanciaApi";
import { UserContext } from '../../middleware/userContext';
import { DataGrid } from '@mui/x-data-grid';

import { useSnackbar } from 'notistack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function UserManager() {
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { logoutUserContext } = useContext(UserContext);
    const [rows, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [deletedUser, setDeletedUser] = useState([]);

    //var [columns, setColumns] = useState([]);
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const columns = [
        {
            field: 'name', headerName: 'Meno', flex: 1, align: "center",
            headerAlign: 'center',
        },
        {
            field: 'surname', headerName: 'Priezvisko', flex: 1, align: "center",
            headerAlign: 'center',
        },
        {
            field: 'personCategory', headerName: 'Kategória', flex: 1, align: "center",
            headerAlign: 'center',
        },
        {
            field: 'email', headerName: 'Email', flex: 1, align: "center",
            headerAlign: 'center',
        },
        {
            field: 'action',
            headerName: 'Akcia',
            width: 100,
            sortable: false,
            disableClickEventBubbling: true,
            align: "center",
            headerAlign: 'center',
            renderCell: (params) => {
                const onClickDelete = () => {
                    const currentRow = params.row;
                    setDeletedUser(currentRow);
                    setOpen(true);
                };

                const onClickEdit = () => {
                    const userInfo = params.row;
                    navigate("/uprava_pouzivatela", { state: { userInfo } });
                };

                return (
                    <Stack direction="row" spacing={1}>
                        <IconButton onClick={(e) => onClickEdit()} aria-label="delete">
                            <EditIcon fontSize="medium" />
                        </IconButton>
                        <IconButton onClick={() => onClickDelete()} aria-label="delete" >
                            <DeleteIcon fontSize="medium" />
                        </IconButton>
                    </Stack>
                );
            },
        },
    ];

    const getAllUser = React.useCallback(async () => {
        try {
            const apiResponseAllUsers = await ambulanciaApi.get("/admin/getAllUser");
            setRows(apiResponseAllUsers.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie");
            }
        }
    }, [logoutUserContext, navigate]);

    useEffect(() => {
        getAllUser();
    }, [getAllUser]);


    const deleteUser = async () => {
        setOpen(false);
        try {
            await ambulanciaApi.delete("/admin/user/" + deletedUser._id);
            getAllUser();
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                logoutUserContext();
                navigate("/prihlasenie")

            }
            return
        }
        enqueueSnackbar('Používateľ bol úspešne odstránený !', { variant: 'success' });
    }


    return (
        <>
            <Container maxWidth="xl">
                <h1 className='centerToMiddle'>Správa používateľov</h1>
                <br></br>
                <Box sx={{ display: 'flex', height: 550, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        getRowId={(rows) => rows._id}
                        // checkboxSelection
                        disableSelectionOnClick
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    Databáza používatľov je prázdna. Začnite vytvorením nového používateľa
                                </Stack>
                            ),
                            NoResultsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    Zadanému filtru nevyhovuje žiadny záznam
                                </Stack>
                            )
                        }}
                        experimentalFeatures={{ newEditingApi: true }}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                    />
                </Box>
                <IconButton onClick={() => {
                    navigate("/registracia");
                }} aria-label="delete" size="large">
                    <AddCircleOutlineIcon fontSize="large" />
                </IconButton>


                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Upozornenie
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Naozaj chcete vymazať používateľa {deletedUser.name + " " + deletedUser.surname} ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Nie</Button>
                        <Button onClick={deleteUser} autoFocus>
                            Áno
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
};

export default UserManager;