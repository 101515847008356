import * as React from 'react';
// eslint-disable-next-line
import { BrowserRouter as  Router, Route, Routes, Link  } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';


import LoginPage from "../auth/loginPage";
import HomePage from "../pages/homePage";
import RegisterPage from '../auth/registerPage';
import NotFound from '../pages/notFound';


function NavbarUnauthorized() {
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar disableGutters>
                        <Avatar alt="Logo_zub" src={require('../images/zub_logo.png')} />
                        <Typography variant="h6" noWrap component="div">
                            Zubná ambulancia
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Box>
            {/* *****ROUTES FOR UNLOGGED USER***** */}
            <Routes>
                <Route exact path="/domov" element={<HomePage />} />
                <Route path="/prihlasenie" element={<LoginPage />} />
                <Route path="/registracia" element={<RegisterPage />} />


                {/* TIETO ROUTY MUSIA BYŤ RENDEROVANÉ AKO POSLEDNÉ!!! */}
                <Route path="/404" element={<NotFound />} />
                <Route path="/" element={<HomePage />} />
                <Route path="*" element={<NotFound />} />

            </Routes>

        </>
    );
};

export default NavbarUnauthorized;
