import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ambulanciaApi from "../api/ambulanciaApi";
import 'react-phone-input-2/lib/material.css'
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { green } from '@mui/material/colors';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { styles } from "../pages/styles";



function RegisterPage() {

    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    //otvorenie modal okna po uspesnej registracii
    const [open, setOpen] = React.useState(false);

    //atribúty pre validáciu formulára priamo počas písania
    const [emailText, setEmailText] = React.useState("");
    const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = React.useState("");

    //regexy pre validaciu na klientovi
    const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/;

    const [category, setCategory] = React.useState("Doktor");

    const handleChange = (event) => {
        setCategory(event.target.value);
    };


    /*Validácia emailu */
    React.useEffect(() => {
        if (!regexEmail.test(emailText)) {
            setEmailErrorMessage(
                "Neplatný email"
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailText]);

    /*Validácia emailu */
    React.useEffect(() => {
        if (emailText.length < 1 || regexEmail.test(emailText)) {
            setEmailErrorMessage("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailText, emailErrorMessage]);

    /*Validácia hesla */
    React.useEffect(() => {
        if (!regexPassword.test(password)) {
            setPasswordErrorMessage(
                "Heslo musí obsahovať aspoň 8 znakov, aspoň jednu číslicu , aspoň jeden malý a aspoň jeden veľký znak"
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password]);

    /*Validácia hesla */
    React.useEffect(() => {
        if (regexPassword.test(password)) {
            setPasswordErrorMessage("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password, passwordErrorMessage]);

    /*Validácia potvrdenia hesla */
    React.useEffect(() => {
        if (!(password === confirmPassword)) {
            setConfirmPasswordErrorMessage(
                "Heslá sa nezhodujú"
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmPassword]);

    /*Validácia potvrdenia hesla */
    React.useEffect(() => {
        if (password === confirmPassword) {
            setConfirmPasswordErrorMessage("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmPassword, confirmPasswordErrorMessage]);


    /* Registrácia usera s validáciou zo servera*/
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let email = data.get("email");
        let password = data.get("password");
        let name = data.get("name");
        let surname = data.get("surname");
        let password_repeat = data.get("confirmPassword");
        let personCategory = data.get("category");
        //console.log(email, password, uciID, telephoneNumber, confirmPassword);

        if (password === confirmPassword) {
            try {
                const apiResponse = await ambulanciaApi.post("/admin/signup",
                    {
                        email,
                        password,
                        password_repeat,
                        name,
                        surname,
                        personCategory
                    },
                );
                if (apiResponse.status === 201) {
                    setOpen(true);
                    setTimeout(() => navigate("/sprava_uctov"), 3000);
                }
            }

            catch (error) {
                if (error.response.status === 400) {
                    console.error("Api ERROR: ", error.response.data);
                    if (error.response.data.message === "validation.email_already_exist")
                        enqueueSnackbar('Zadaný E-mail sa už používa!', { variant: 'error' });
                    else
                        enqueueSnackbar('Vyskytla sa chyba!', { variant: 'error' });
                }
                else {
                    enqueueSnackbar('Vyskytla sa chyba!', { variant: 'error' });
                }
            }
        } else {
            console.error("Zadané heslá sa nezhodujú!");
            enqueueSnackbar('Zadané heslá sa nezhodujú!', { variant: 'warning' });
        }

    }


    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    p: 4,
                    borderRadius: 6
                }}
                elevation={4}
                align="center"
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Vytvorenie nového používateľa
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl sx={{ display: "flex" }}>
                                <InputLabel id="demo-simple-select-helper-label">Kategória</InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-helper-label"
                                    id="category"
                                    name="category"
                                    value={category}
                                    label="Kategória"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"Doktor"}>Doktor</MenuItem>
                                    <MenuItem value={"Dentálny hygienik"}>Dentálny hygienik</MenuItem>
                                    <MenuItem value={"Zdravotná sestrička"}>Zdravotná sestrička</MenuItem>
                                    <MenuItem value={"Recepcia"}>Recepcia</MenuItem>
                                </Select>

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="name"
                                type="name"
                                required
                                fullWidth
                                id="name"
                                label="Meno"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                type="surname"
                                id="surname"
                                label="Priezvisko"
                                name="surname"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                type="Email"
                                error={!regexEmail.test(emailText) && emailText.length > 0}
                                onChange={(e) => setEmailText(e.target.value)}
                                helperText={emailErrorMessage}
                                value={emailText}
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete='off'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="password"
                                type="password"
                                required
                                fullWidth
                                error={!regexPassword.test(password) && password.length > 0}
                                onChange={(e) => setPassword(e.target.value)}
                                helperText={password.length > 0 ? passwordErrorMessage : ""}
                                value={password}
                                id="password"
                                label="Heslo"
                                autoComplete='new-password'
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                type="password"
                                error={!(password === confirmPassword)}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                helperText={confirmPassword.length > 0 ? confirmPasswordErrorMessage : ""}
                                value={confirmPassword}
                                id="confirmPassword"
                                label="Potvrďte heslo"
                                name="confirmPassword"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        //fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Vytvoriť používateľa
                    </Button>
                </Box>
            </Paper>
            <div>
                <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={styles.BoxSuccess} >
                        <Typography id="modal-modal-title" variant="h5" component="h2" align="center">
                            Registárcia úspešná !
                        </Typography>
                        <Container align="center">
                            <Avatar sx={{ width: 100, height: 100, bgcolor: "white" }}  >
                                <CheckCircleOutlinedIcon sx={{ width: 100, height: 100, color: green[500] }} />
                            </Avatar>
                        </Container>
                    </Box>
                </Modal>
            </div>

        </Container>
    );
}

export default RegisterPage;